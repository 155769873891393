import { useState, useEffect } from 'react'
import ReactPlayer from 'react-player'
import Container from 'react-bootstrap/Container';
import { Helmet } from 'react-helmet';
import ListGroup from 'react-bootstrap/ListGroup';
import { useParams } from 'react-router';
import Card from 'react-bootstrap/Card';
import { concertJsonToObject, ConcertResponse, Concert } from '../data/concertData';
import { Link } from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

export default function ConcertDetail(props: any) {
  const params = useParams()
  let id = Number(params['id'])
  const [concert, setConcert] = useState({} as Concert)
  const [artistId, setArtistId] = useState(0)
  const [song, setSong] = useState([] as any[])

  useEffect(() => {
    fetch(`https://api.concertfor.me/api/concerts/${id}`)
      .then(function (response) {
        return response.text();
      })
      .then(function (myJson) {
        let concertResponse = concertJsonToObject(myJson)
        setArtistId(concertResponse.artists[0][0])
        setSong(concertResponse.songs.length > 0 ? concertResponse.songs[0][0] : [0, ''])
        setConcert(concertResponse.concerts[0])
      })
  }, [])


  return (
    <Container style ={{marginTop:"65", display: 'flex', justifyContent: 'center'}}>
      <Helmet>
        <title>Concert Detail</title>
      </Helmet>
      <Row style = {{marginTop:100}}>
        <Col>
        <Card style={{ width: '25rem' }}>
          <Card.Img variant="top" src={concert.image_url} />
          <Card.Body>
            <Card.Title>{concert.event_name}</Card.Title>
            <Card.Text>
              <b>Event: </b> {concert.event_name} <br></br>
              <b>Venue: </b>  {concert.venue_name} <br></br>
              <b>Address: </b>  {concert.venue_address} <br></br>
              <b>Attendance: </b> {concert.predicted_attendance} <br></br>
              <b>Artist: </b> {concert.artist_name}  <br></br>
              <b>Date: </b> {concert.start_date}  <br></br>
              <b>Time: </b> {concert.start_time}  <br></br>
              <b>Time Zone: </b> {concert.time_zone}  <br></br>
            </Card.Text>
            <Card.Text>
              <Link to={`/artistdetail/${artistId}`}> Check out this artist </Link>
            </Card.Text>
            <Card.Text>
              <Link to={`/songs/${song}`}> Check out songs by artist</Link>
            </Card.Text>
          </Card.Body>
        </Card>
        </Col>
        <Col>
        <Card style={{ width: '30rem' }}>
        <div style = {{display: 'flex', justifyContent: 'center', marginTop: '10px'}}> <h3>Directions to Event</h3></div>
        <div style = {{display: 'flex', justifyContent: 'center', marginTop: '10px'}}>
        <iframe
          width="450"
          height="250"
          frameBorder="0"
          referrerPolicy="no-referrer-when-downgrade"
          src={"https://www.google.com/maps/embed/v1/place?key=AIzaSyCFEwlBU9cPr1yJdXKV1Z_LZ6s2wwfL1ko&q=" + concert.venue_name}
          allowFullScreen>
        </iframe>
        </div>
        <div style = {{display: 'flex', justifyContent: 'center', marginTop: '10px'}}> <h3>Event Description: {concert.event_name}</h3></div>
        <p>
          {concert.event_description}
        </p>
      </Card>
        </Col>
      </Row>
    </Container>
  );
}
