import React, { Component, useState, useEffect } from 'react'
import { artistsJsonToObject } from '../data/artistData'
import { concertsJsonToObject } from '../data/concertData'

import { addressit } from 'addressit'
import BarChartJS from '../components/BarChart';
import PieChartJS from '../components/PieChart';

const Visualizations = () => {
    var addressit = require('addressit');


    const [genreToArtistCount, setGenreToArtistCount] = useState(new Map())
    const [countryToArtistCount, setCountryToArtistCount] = useState(new Map())
    const [stateToConcertCount, setstateToConcertCount] = useState(new Map())

    useEffect(() => {
        // set genreToArtistCount
        fetch('http://api.concertfor.me/api/artists?n=1000000000', {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        })
            .then(function (response) {
                return response.text();
            })
            .then(function (myJson) {
                let artistsResponse = artistsJsonToObject(myJson)
                let artists = artistsResponse.artists

                // genreToArtistCount
                let map = new Map();
                artists.forEach(function (artist) {
                    map.set(artist.genre, map.get(artist.genre) == undefined ? 1 : (map.get(artist.genre)) + 1);
                });
                let mapSorted = new Map([...map.entries()].sort((a, b) => b[1] - a[1]))
                setGenreToArtistCount(mapSorted);

                // countryToArtistCount
                map = new Map();
                artists.forEach(function (artist) {
                    map.set(artist.home_country, map.get(artist.home_country) == undefined ? 1 : (map.get(artist.home_country)) + 1);
                });
                mapSorted = new Map([...map.entries()].sort((a, b) => b[1] - a[1]))
                setCountryToArtistCount(mapSorted)
            });
        // set stateToConcertCount
        fetch('http://api.concertfor.me/api/concerts?n=1000000000', {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        })
            .then(function (response) {
                return response.text();
            })
            .then(function (myJson) {
                let concertsResponse = concertsJsonToObject(myJson)
                let concerts = concertsResponse.concerts

                // genreToArtistCount
                let map = new Map();
                concerts.forEach(function (concert) {
                    let location = addressit(concert.venue_address)
                    let state = location == undefined || location.state == undefined ? "Unable to Parse" : location.state
                    map.set(state, map.get(state) == undefined ? 1 : (map.get(state)) + 1);
                });
                let mapSorted = new Map([...map.entries()].sort((a, b) => b[1] - a[1]))
                setstateToConcertCount(mapSorted);


            });

    }, [])




    return (
        <div style={{ marginTop: 100, textAlign: "center" }}>
            <h1>Visualizations</h1>
            <h2>Artist Genre to Artist Count</h2>
            <BarChartJS xLabels={Array.from(genreToArtistCount.keys())} dataSetLabel={"# Of Artists"} yData={Array.from(genreToArtistCount.values())}></BarChartJS>
            <h2>Home Country To Artist Count</h2>
            <PieChartJS xLabels={Array.from(countryToArtistCount.keys())} dataSetLabel={"# Of Artists"} yData={Array.from(countryToArtistCount.values())}></PieChartJS>
            <h2>State To Concert Count</h2>
            <BarChartJS xLabels={Array.from(stateToConcertCount.keys())} dataSetLabel={"# Of Concerts"} yData={Array.from(stateToConcertCount.values())}></BarChartJS>
        </div>
    )
};

export default Visualizations;