import React, { useState } from 'react'
import { Helmet } from 'react-helmet';
import ConcertCard from './ConcertCard';
import ListGroup from 'react-bootstrap/ListGroup';
import { dev_about_list } from "../data/aboutData"
import Container from 'react-bootstrap/Container';
import { Grid } from "@mui/material";


import IndividualAbout from './IndividualAbout';
import { ContributorInfo, findContributorInfoWithName } from '../utils/GitLabUtils';

const ListOfIndividualAbout = (props: any) => {
  let listOfContributorInfo: ContributorInfo[] = props.listOfContributorInfo
  return (
    <div>
      <Grid container spacing={2} columns={20} pb={8}>
        {dev_about_list.map((data, index) => {
          let contributorInfo = findContributorInfoWithName(listOfContributorInfo, data.gitLabName)
          let commitsCount = 0
          if (contributorInfo != null) {
            commitsCount = (contributorInfo as ContributorInfo).commits
          }
          return (
                <Grid item xs={4}>
              <IndividualAbout
                name={data.name}
                photo={data.photo}
                bio={data.bio}
                responsibilities={data.responsibilities}
                commitsCount={commitsCount}
                unitTestsCount={data.unitTestsCount}
                gitLabId={data.gitLabId}
                gitLabName={data.gitLabName}
              />
            </Grid>
          );
        })}
      </Grid>
    </div>
  )
}
export default ListOfIndividualAbout