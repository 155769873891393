import React from "react";
import { Bar } from "react-chartjs-2";
import Chart from "chart.js/auto"
import './ChartStyle.css';

export const BarChartJS = ({ xLabels, dataSetLabel, yData }) => {
    const data = {
        labels: xLabels,
        datasets: [
            {
                label: dataSetLabel,
                backgroundColor: "rgb(38, 100, 245)",
                borderColor: "rgb(38, 100, 245)",
                data: yData,
            },
        ],
    };
    return (
        <div className="chart">
            <Bar data={data} />
        </div>
    );
};

export default BarChartJS;