
export type ArtistsResponse = Artist[]

export interface Artist {
    id?: number
    spotify_id?: string
    name?: string
    track?: string
    track_id?: string
    album?: string
    album_id?: string
    spotify_url?: string
    cover_art?: string
    popularity?: number
    genre?: string
    num_followers?: number
    total_results?: number
}

export function artistsJsonToObject(json: string): ArtistsResponse {
    let result: ArtistsResponse = JSON.parse(json)
    return result;
}





