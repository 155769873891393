import React from 'react'
import ListGroup from 'react-bootstrap/ListGroup';
import { about_tools } from '../data/aboutData';
import { ToolCard } from './ToolCard';
import { Grid } from "@mui/material";
import Card from 'react-bootstrap/Card';
const ListToolCards = () => {

    return (
        <Grid container spacing={1} columns={12} pb={8}>
            {about_tools.map((data, index) => {
                return (
                    <Grid item xs={3}>
                        <ToolCard
                            name={data.name}
                            use={data.use}
                            photo={data.photo}
                            link={data.link}
                        />
                    </Grid>
                );
            })}
        </Grid>




        // <Grid container spacing={1} columns={12} pb = {8}>
        //     {about_tools.map((data, index) => {
        //         return (
        //             <Grid item xs={3}>
        //                 <Card style={{ width: '18rem' }}>
        //             <Card.Body>
        //             <Card.Img variant="top" src={data.photo} className="card-img-top" />
        //                 <Card.Title>{data.name}</Card.Title>
        //                 <Card.Text>
        //                     Use: {data.use} <br></br>
        //                 </Card.Text>
        //                 <Card.Link>{data.link}</Card.Link>
        //             </Card.Body>
        //         </Card>
        //             </Grid>
        //         );
        //     })}
        // </Grid>
    )
}

export default ListToolCards