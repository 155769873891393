import grantPic from "../assets/developer_photos/grant.jpg"
import divyaPic from "../assets/developer_photos/divya.jpg"
import timothyPic from "../assets/developer_photos/timothy.jpg"
import minhPic from "../assets/developer_photos/Minh.jpg"
import jacksonPic from "../assets/developer_photos/jackson.jpg"
import img1 from "../assets/about_phase2/spotify.png"
import img2 from "../assets/about_phase2/discord.png"
import img3 from "../assets/about_phase2/flask.png"
import img4 from "../assets/about_phase2/react.png"
import img5 from "../assets/about_phase2/materialui.png"
import img6 from "../assets/about_phase2/postman.png"
import img7 from "../assets/about_phase2/reactbootstrap.png"
import img8 from "../assets/about_phase2/namecheap.png"
import img9 from "../assets/about_phase2/aws.png"
import img10 from "../assets/about_phase2/gitlab.png"
import img11 from "../assets/about_phase2/postgres.png"

import gitlablogo from "../assets/api_logo/gitlab.png"
import musicbrainz from "../assets/api_logo/musicbrainz.png"
import microsoft from "../assets/api_logo/microsoft.png"
import docker from "../assets/api_logo/docker.png"



import ituneslogo from "../assets/api_logo/itunes.png"
import predictHQlogo from "../assets/api_logo/predictHQ.png"
export const dev_about_list = [
    {
        name: "Grant Martin",
        photo: grantPic,
        bio: "Grant is 20 years old. He originally grew up in Houston,Tx. He is currently a Junior CS student at UT Austin.",
        responsibilities: "Full Stack",
        gitLabId: "grantmartin2002",
        gitLabName: "Grant Martin",
        unitTestsCount: 37
    },
    {
        name: "Divya Kalanee",
        photo: divyaPic,
        bio: "Divya is 20 years old. She grew up in Houston, TX and moved to Dubai before coming back to Texas. She is a junior CS student at UT Austin.",
        responsibilities: "Full Stack",
        gitLabId: "divyakalanee",
        gitLabName: "Divya Kalanee",
        unitTestsCount: 5
    },
    {
        name: "Timothy Zhang",
        photo: timothyPic,
        bio: "Timothy is a Senior CS student at UT Austin. He's from Plano, TX.",
        responsibilities: "Back End",
        gitLabId: "zhang.timothy",
        gitLabName: "zhangtimothy",
        unitTestsCount: 12
    },
    {
        name: "Minh Nguyen",
        photo: minhPic,
        bio: "Minh is a Senior CS student at UT Austin. He comes from Plano, TX",
        responsibilities: "Full Stack",
        gitLabId: "nhminhng",
        gitLabName: "Minh Nguyen",
        unitTestsCount: 0
    },
    {
        name: "Jackson Nakos",
        photo: jacksonPic,
        bio: "Jackson is 20 years old, from Frisco, TX, and is a third year Computer Science Major at UT Austin.",
        responsibilities: "Back End",
        gitLabId: "jacksonnakos",
        gitLabName: "Jackson Mickey Nakos",
        unitTestsCount: 0
    }
];
export const about_apis = [
    {
        name: "Spotify",
        use: "To get Artist information.",
        howItWasScraped: " In this phase, A GET request is made to the Spotify API for information about the top n most popular artists. This returns a JSON  which contains a list of JSONs, one for each artist. These are iterated over and relevant fields are extracted from the nested JSON. These are compiled intoa single JSON which contains only extracted relevant fields, in a flat structure",
        photo: img1,
        link: "https://developer.spotify.com/documentation/web-api/"
    }, {
        name: "PredictHQ",
        use: "To get upcoming Concerts information.",
        howItWasScraped: " In this phase, A GET request is made to the PredictHQ API for information about the top n most relevant events, filtered by the Concert tag. This returns a JSON which contains a list of JSONs, one for each event. These are iterated over, and relevant fields are extracted from the nested JSON. These are compiled into a single JSON which contains only extracted relevant fields, in a flat structure.",
        photo: predictHQlogo,
        link: "https://www.predicthq.com/"
    }, {
        name: "Itunes",
        use: "To get Song information.",
        howItWasScraped: " In this phase, A GET request is made to the Itunes API for information about the top n most relevant songs, filtered by the Song tag. This returns a JSON which contains a list of JSONs, one for each song. These are iterated over, and relevant fields are extracted from the nested JSON. These are compiled into a single JSON which contains only extracted relevant fields, in a flat structure.",
        photo: ituneslogo,
        link: "https://developer.apple.com/library/archive/documentation/AudioVideo/Conceptual/iTuneSearchAPI/index.html"
    }, {
        name: "GitLab",
        use: "To get commit and issue count data.",
        howItWasScraped: "In this phase, our front-end calls endpoints from the GitLab API in order to display commit and issue count data.",
        photo: gitlablogo,
        link: "https://docs.gitlab.com/ee/api/"
    },
    {
        name: "MusicBrainz",
        use: "To get additional attribute data for artists.",
        howItWasScraped: "MusicBrainz was scraped to get additional information about artists such as their home country, gender, birthday, and aliases. A search was queried on this API using the artist_name from the Spotify API as a search term. This additional information was included in each row for Artists in our database table.",
        photo: musicbrainz,
        link: "https://musicbrainz.org/ "
    },
    {
        name: "Microsoft Azure Image Search",
        use: "To get an image for concert venues",
        howItWasScraped: "Bing Image Search API was scraped in order to get an image for the venue of each our our concerts. While getting data for each row of our Concerts table in our database, most of  the columns would be retrieved by querying the PredictHQ API. From this result, we extract the concert venue name, and use that as a search query term in the Bing Image Search API. We use the URL of the top search result and include it in our table row.",
        photo: microsoft,
        link: "https://learn.microsoft.com/en-us/rest/api/cognitiveservices/bingvisualsearch/images/visual-search?tabs=HTTP "
    },
]
export const about_tools = [
    {
        name: "Spotipy",
        use: "This library helped handle some calls to the Spotify API.",
        photo: img1,
        link: "https://developer.spotify.com/documentation/web-api/"
    },
    {
        name: "GitLab",
        use: "We used GitLab as our version control.",
        photo: img10,
        link: "https://gitlab.com/"
    },
    {
        name: "React Bootstrap",
        use: "We used React Boostrap to implement some of our components to the front-end",
        photo: img7,
        link: "https://react-bootstrap.github.io/"
    },
    {
        name: "Material UI",
        use: "We used Material UI to implement some of our components to the front-end",
        photo: img5,
        link: "https://mui.com/"
    }, {
        name: "AWS",
        use: "We used AWS to host our website",
        photo: img9,
        link: "https://aws.amazon.com/"
    }, {
        name: "Discord",
        use: "Discord was our main means of communication",
        photo: img2,
        link: "https://discord.com/"
    }, {
        name: "Namecheap",
        use: "We retreived our domain name from Namecheap",
        photo: img8,
        link: "https://www.namecheap.com/"
    }, {
        name: "Postman",
        use: "We used Postman to document our API",
        photo: img6,
        link: "https://www.postman.com/"
    }, {
        name: "React",
        use: "Our website was built using the React framework",
        photo: img4,
        link: "https://reactjs.org/"
    },
    {
        name: "Flask",
        use: "Helps connect front-end and back-end",
        photo: img3,
        link: "https://flask.palletsprojects.com/en/2.2.x/"
    },
    {
        name: "Postgres",
        use: "Hosts our data-base for backend",
        photo: img11,
        link: "https://www.postgresql.org/"
    },
    {
        name: "Docker",
        use: "Runs our application in environment",
        photo: docker,
        link: "https://www.docker.com/"
    },
]
