import Carousel from 'react-bootstrap/Carousel';
import img1 from '../assets/concertcarousel.jpg';
import img2 from '../assets/snoopcarousel.jpg';
import img3 from '../assets/musiccarousel.jpg';
import img4 from '../assets/concertcarousel2.jpg';

import './HomePage.css';


function UncontrolledExample() {
  return (
    <div>
      <Carousel style={{ marginTop: 65 }}>
        <Carousel.Item>
          <img
            className="d-block w-100 "
            src={img1}
            height="600vh"
            object-fit="cover"
            alt="First slide"
          />
          <Carousel.Caption>
            <h3>Find upcoming concerts</h3>
            <p>With your favorite artists & your favorite songs</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={img4}
            height='600vh'
            object-fit="cover"
            alt="Second slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block h-80 w-100"
            src={img2}
            height="600vh"
            object-fit="cover"
            alt="Third slide"
          />
        </Carousel.Item>
      </Carousel>
    </div>
  );
}

export default UncontrolledExample;