
export type TracksResponse = Track[]

export interface Track {
    id: number;
    spotify_id: string;
    album: string;
    album_id: string;
    artist: string;
    artist_id: string;
    spotify_url: string;
    cover_art: string;
    popularity: number;
    release_date: string;
    genre: string;
    name: string;
    total_results?: number;

}

export function tracksJsonToObject(json: string): TracksResponse {
    let result: TracksResponse = JSON.parse(json)
    return result;
}





