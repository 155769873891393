import React, { useState, useEffect } from 'react';
import { Box, Pagination } from "@mui/material";

//citation: https://www.youtube.com/watch?v=37xPlDBaA4A&ab_channel=Grepsoft

// const pageSize = 10;

export default function AppPagination({ setInstances, setTotalCount, service, generalRequestLink, inputPageSize }) {

    let pageSize = inputPageSize
    const [pagination, setPagination] = useState({
        count: 0,
        from: 0,
        to: pageSize,
    })

    useEffect(() => {
        service.getData({ from: pagination.from, to: pagination.to, requestLink: generalRequestLink }).then(response => {
            setPagination({ ...pagination, count: response.count });
            setInstances(response.data)
            setTotalCount(response.count)
        });
    }, [pagination.from, pagination.to, generalRequestLink]);

    const handlePageChange = (event, page) => {
        const from = (page - 1) * pageSize
        const to = (page - 1) * pageSize + pageSize;
        setPagination({ ...pagination, from: from, to: to });
    }

    return (
        <Box justifyContent={"center"} alignItems="center" display={"flex"} sx={{ margin: "20px 0px" }}
        ><Pagination
                count={Math.ceil(pagination.count / pageSize)}
                onChange={handlePageChange}
            />
        </Box>
    )
}