import React from 'react'
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';

export const ToolCard = (props: any) => {
    let name = props.name
    let use = props.use
    let photo = props.photo
    let link = props.link
    return (
        <Container style={{ width: "100%"}}>
            <div>
                <Card style={{ width: '18rem' }}>
                    <Card.Body>
                        <Card.Img variant="top" src={photo}/>
                        <Card.Title>{name}</Card.Title>
                        <Card.Text>
                            Use: {use} <br></br>
                        </Card.Text>
                        <Card.Link href={link}>Link To Tool</Card.Link>
                    </Card.Body>
                </Card>
            </div>
        </Container>
    )
}
