import { Box } from '@mui/material';
import SplashCards from './SplashCards';
import { Link } from 'react-router-dom';
import React, { useState, useEffect } from 'react'
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { song_list } from '../data/songData';
import { useParams } from 'react-router';
import { Helmet } from 'react-helmet';
import ReactPlayer from 'react-player'
import Embed from 'react-music-embed'
import './SongCard.css';
import PropTypes from "prop-types";
import { convertReleaseDate } from './SongCard.js'
import { millisToMinutesAndSeconds } from './SongCard.js'
import { songJsonToObject, SongResponse, Song } from '../data/songData';
import Concerts from '../routes/Concerts';


function explicitness(explicit_str: string) {
    if (explicit_str == "notExplicit") {
        return "Clean"
    } else {
        return "Explicit"
    }
}

export default function SongDetail(props: any) {
    const params = useParams()
    let id = Number(params['id'])
    const [song, setSong] = useState({} as Song)
    const [artistId, setArtistId] = useState(0)
    const [concertId, setConcertId] = useState([] as any[])

    useEffect(() => {
        fetch(`https://api.concertfor.me/api/songs/${id}`)
            .then(function (response) {
                return response.text();
            })
            .then(function (myJson) {
                let songResponse = songJsonToObject(myJson)
                setArtistId(songResponse.artists[0][0])
                setSong(songResponse.songs[0])
                setConcertId(songResponse.concerts[0][0])
            })
    }, [])
    return (
        <Container style={{ marginTop: 65, display: 'flex', justifyContent: 'center', backgroundColor: '#F7EBF5'}}>
            <Helmet>
                <title>Song Detail</title>
            </Helmet>
            <Row style = {{marginTop: 40}}>
                <Col>
                    <Card style={{ width: '18rem' }}>
                        <Card.Img variant="top" src={song.artwork_url} className='card-img' />
                        <Card.Body>
                            <Card.Title> <i>{song.song_name} </i></Card.Title>
                            <Card.Text>
                                <b>Artist: </b> {song.artists} <br></br>
                                <b>Album: </b> {song.album} <br></br>
                                <b>Genre:</b>  {song.genre} <br></br>
                                <b> Release Date:</b> {convertReleaseDate(song.release_date)} <br></br>
                                <b>Song Length: </b> {millisToMinutesAndSeconds(song.track_length)} <br></br>
                                <b>Track Price: </b> {song.price} {song.currency} <br></br>
                                <b>Track Explicitness: </b> {explicitness(song.explicit)} <br></br>
                                <b >Country: </b> {song.country}<br></br>
                                <b>Track # on Album: </b> {song.track_number} <br></br>
                                <b># Tracks on Album: </b> {song.track_count}
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card style={{ width: '40rem' }}>
                        <ListGroup className="list-group-flush">
                            <ListGroup.Item>
                                <Embed url={song.track_view_url} />
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <a href={song.artist_view_url}>                             <Button variant="warning"> {song.artists} on Apple Music </     Button> </a>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <a href={song.preview_url}>                             <Button variant="dark"> Download iTunes Music Preview </     Button> </a>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <Link to={`/artistdetail/${artistId}`}>
                                    Check out {song.artists}
                                </Link>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <Link to={`/concerts/${concertId}`} >
                                    Check out concerts by {song.artists}
                                </Link>
                            </ListGroup.Item>
                        </ListGroup>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};
