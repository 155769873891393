import { Link } from 'react-router-dom';
import {
  Container,
  Grid,
  Typography,
  Card,
  CardContent,
  CardMedia,
  CardActionArea,
  Box,
} from '@mui/material';
import songs_img from "../assets/songimage.jpg"
import artists_img from "../assets/artistimage.png"
import concerts_img from "../assets/concertimage.jpg"

// Code from CatchingCongress: https://gitlab.com/catchingcongress/catchingcongress

const SplashCards = () => {
  return (
    <Box style={{marginTop: -100}}>
      <Container >
        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ md: 12 }} pt={10} pb = {6}>
          <Grid item md={4}>
            <Link to="/songs" style={{ textDecoration: 'none' }}>
              <Card id="Songs Card" sx={{ maxWidth: 375 }}>
                <CardActionArea style={{ outline: 'none' }}>
                  <CardMedia component="img" height="200" image={songs_img} />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      Songs
                    </Typography>
                    <Box sx={{ minHeight: '7vh' }}>
                      <Typography variant="body1" color="text.secondary">
                        Find some songs by your favorite artists!
                      </Typography>
                    </Box>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Link>
          </Grid>

          <Grid item md={4}>
            <Link to="/artists" style={{ textDecoration: 'none' }}>
              <Card id="artistCard" sx={{ maxWidth: 375 }}>
                <CardActionArea style={{ outline: 'none' }}>
                  <CardMedia component="img" height="200" image={artists_img} />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      Artists
                    </Typography>
                    <Box sx={{ minHeight: '7vh' }}>
                      <Typography variant="body1" color="text.secondary">
                        Find your favorite artists and more!
                      </Typography>
                    </Box>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Link>
          </Grid>

          <Grid item md={4}>
            <Link to="/concerts" style={{ textDecoration: 'none' }}>
              <Card id="concertCard" sx={{ maxWidth: 375 }}>
                <CardActionArea style={{ outline: 'none' }}>
                  <CardMedia component="img" height="200" image={concerts_img} />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      Concerts
                    </Typography>
                    <Box sx={{ minHeight: '7vh' }}>
                      <Typography variant="body1" color="text.secondary">
                        See upcoming concerts from your favorite artists!
                      </Typography>
                    </Box>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Link>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default SplashCards;
