import { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet';
import ArtistCard from '../components/ArtistCard';
import AppPagination from '../components/AppPagination';
import { Grid } from "@mui/material";
import { artistsJsonToObject, artist_page_service } from "../data/artistData"
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";

import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Slider from '@mui/material/Slider';

const Artists = () => {

  const [wordEntered, setWordEntered] = useState("");

  const handleSearch = (event) => {
    const searchWord = event.target.value;
    setWordEntered(searchWord);
  };

  const clearInput = () => {
    setWordEntered("");
  };

  // for sorting attribute
  const [sortBy, setSortBy] = React.useState("followers");
  const [orderBy, setOrderBy] = React.useState("-");


  //for filter attribute
  const [filteredGender, setFilteredGender] = React.useState("All");
  const [filteredGenre, setFilteredGenre] = React.useState("All");
  const [filteredHomeCountry, setFilteredHomeCountry] = React.useState("All");
  const [followerRange, setFollowerRange] = React.useState([0, 105000000]);
  const marksFollowerRange = [
    {
      value: 0,
      label: '0',
    },
    {
      value: 35000000,
      label: '35M',
    },
    {
      value: 70000000,
      label: '70M',
    },
    {
      value: 105000000,
      label: '105M',
    },
  ];

  const api_test_host = "http://localhost:5003"
  const api_deploy_host = "https://api.concertfor.me"
  let api_host = api_deploy_host

  const [fetch_url, setFetchUrl] = useState("")

  useEffect(() => {

    //api/artists?n=10&filterby=home_country&fquery=US&sortby=-followers
    //api/artists?search=taylor&filterby=home_country&fquery=US&sortby=-followers
    let url = `${api_host}/api/artists?`

    if (wordEntered !== "") {
      url += `search=${wordEntered.toLowerCase()}`
    }



    url = url + "&filterby=followers+followers"
    if (filteredGender !== "All")
      url = url + "+gender"
    if (filteredGenre !== "All")
      url = url + "+genre"
    if (filteredHomeCountry !== "All")
      url = url + "+home_country"


    url = url + `&fquery=>${followerRange[0]}+<${followerRange[1]}`

    if (filteredGender !== "All")
      url = url + "+" + filteredGender
    if (filteredGenre !== "All")
      url = url + "+" + filteredGenre
    if (filteredHomeCountry !== "All")
      url = url + "+" + filteredHomeCountry

    // fetch_url = fetch_url.substring(0, fetch_url.length-1)

    url = url + "&sortby=" + orderBy + sortBy
    setFetchUrl(url)

  }, [wordEntered, filteredGender, filteredGenre, filteredHomeCountry, sortBy, orderBy, followerRange])


  const [page_artists, setPageArtists] = useState([]);
  const [total_rows, setTotalRows] = useState(0);

  return (
    <div>Artists
      <Helmet>
        <title>Find Artists</title>
      </Helmet>


      <div style={{ marginTop: 70, display: "flex", justifyContent: "center", alignItems: "center" }}>
        <input style={{ borderRadius: "15px", height: "40px", fontSize: "15px", width: "200px", padding: "15px" }}
          type="text"
          placeholder=" Enter a keyword..."
          value={wordEntered}
          onChange={handleSearch}
        />
        <div style={{ padding: "10px" }}>
          {wordEntered === "" ? (
            <SearchIcon />
          ) : (
            <CloseIcon id="clearBtn" onClick={clearInput} />
          )}
        </div>

        {/*Sort by menu */}
        <FormControl style={{ margin: "15px" }}>
          <InputLabel style={{ marginBottom: "25" }}>Sort by</InputLabel>
          <Select
            style={{ width: "200px", backgroundColor: "white" }}
            label="Sort By"
            value={sortBy}
            onChange={(event) => {
              setSortBy(event.target.value);
            }}
          >
            <MenuItem value={"artist_name"}>Name</MenuItem>
            <MenuItem value={"birthday"}>Birthday</MenuItem>
            <MenuItem value={"followers"}># Followers</MenuItem>
            <MenuItem value={"popularity"}>Popularity</MenuItem>
          </Select>
        </FormControl>

        {/* Order by menu */}
        <FormControl style={{ margin: "15px" }}>
          <InputLabel style={{ marginBottom: "25" }}>Order by</InputLabel>
          <Select
            style={{ width: "200px", backgroundColor: "white" }}
            label="Order By"
            value={orderBy}
            onChange={(event) => {
              setOrderBy(event.target.value);
            }}
          >
            <MenuItem value={"+"}>Ascending</MenuItem>
            <MenuItem value={"-"}>Descending</MenuItem>
          </Select>
        </FormControl>
      </div>


      <div style={{ display: "flex", marginTop: "10px", justifyContent: "center" }}>
        {/* Filter Gender menu */}
        <FormControl style={{ margin: "15px" }}>
          <InputLabel style={{ marginBottom: "25" }}>Gender</InputLabel>
          <Select
            style={{ width: "120px", backgroundColor: "white" }}
            label="Gender"
            value={filteredGender}
            onChange={(event) => {
              setFilteredGender(event.target.value);
            }}
          >
            <MenuItem value={"All"}>All</MenuItem>
            <MenuItem value={"male"}>Male</MenuItem>
            <MenuItem value={"female"}>Female</MenuItem>
          </Select>
        </FormControl>

        {/* Filter Genre menu */}
        <FormControl style={{ margin: "15px" }}>
          <InputLabel style={{ marginBottom: "25" }}>Genre</InputLabel>
          <Select
            style={{ width: "150px", backgroundColor: "white" }}
            label="Genre"
            value={filteredGenre}
            onChange={(event) => {
              setFilteredGenre(event.target.value);
            }}
          >
            <MenuItem value={"All"}>All</MenuItem>
            <MenuItem value={"singer-songwriter"}>Singer-Songwriter</MenuItem>
            <MenuItem value={"metal"}>Metal</MenuItem>
            <MenuItem value={"edm"}>Boy Band</MenuItem>
            <MenuItem value={"alternative"}>Alternative</MenuItem>
            <MenuItem value={"country"}>Country</MenuItem>
            <MenuItem value={"rock"}>Rock</MenuItem>
            <MenuItem value={"rap"}>Rap</MenuItem>
            <MenuItem value={"soul"}>Soul</MenuItem>
            <MenuItem value={"pop"}>Pop</MenuItem>
            <MenuItem value={"techno"}>Techno</MenuItem>
            <MenuItem value={"indie"}>Indie</MenuItem>
            <MenuItem value={"hip hop"}>Hip Hop</MenuItem>
            <MenuItem value={"k-pop"}>K-Pop</MenuItem>
            <MenuItem value={"shoegaze"}>Shoegaze</MenuItem>
            <MenuItem value={"hardcode"}>Hardcode</MenuItem>
            <MenuItem value={"anime"}>Anime</MenuItem>
            <MenuItem value={"r%b"}>R&B</MenuItem>
            <MenuItem value={"punk"}>Punk</MenuItem>
            <MenuItem value={"christian"}>Christian</MenuItem>
            <MenuItem value={"reggae"}>Reggae</MenuItem>
            <MenuItem value={"orchestra"}>Orchestra</MenuItem>
            <MenuItem value={"folk"}>Folk</MenuItem>
            <MenuItem value={"lullaby"}>Lullaby</MenuItem>
            <MenuItem value={"blues"}>Blues</MenuItem>

          </Select>
        </FormControl>

        {/* Filter Home Country menu */}
        <FormControl style={{ margin: "15px", marginLeft: "15px" }}>
          <InputLabel style={{ marginBottom: "25" }}>Home Country</InputLabel>
          <Select
            style={{ width: "150px", backgroundColor: "white" }}
            label="Home Country"
            value={filteredHomeCountry}
            onChange={(event) => {
              setFilteredHomeCountry(event.target.value);
            }}
          >
            <MenuItem value={"All"}>All</MenuItem>
            <MenuItem value={"US"}>US</MenuItem>
            <MenuItem value={"MX"}>MX</MenuItem>
            <MenuItem value={"PL"}>PL</MenuItem>
            <MenuItem value={"CA"}>CA</MenuItem>
            <MenuItem value={"PR"}>PR</MenuItem>
            <MenuItem value={"KR"}>KR</MenuItem>
            <MenuItem value={"IL"}>IL</MenuItem>
            <MenuItem value={"JP"}>JP</MenuItem>
            <MenuItem value={"IT"}>IT</MenuItem>
            <MenuItem value={"GB"}>GB</MenuItem>
            <MenuItem value={"TR"}>TR</MenuItem>
            <MenuItem value={"CR"}>CR</MenuItem>
            <MenuItem value={"AU"}>AU</MenuItem>
            <MenuItem value={"NL"}>NL</MenuItem>
            <MenuItem value={"SE"}>SE</MenuItem>
            <MenuItem value={"BE"}>BE</MenuItem>
            <MenuItem value={"DE"}>DE</MenuItem>
            <MenuItem value={"IE"}>IE</MenuItem>
          </Select>
        </FormControl>

        {/* Filter Num Follower*/}
        <div style={{ width: 300, marginLeft: "20px", textAlign: 'center' }} >
          <p style={{ fontSize: 16, marginTop: 10 }}>Number of followers</p>
          <Slider
            value={followerRange}
            onChange={(event, newFollowerRange) => {
              setFollowerRange(newFollowerRange);
            }}
            valueLabelDisplay="auto"
            step={5000000}
            min={0}
            max={105000000}
            marks={marksFollowerRange}
          />
        </div>

      </div>
      <div style={{ marginTop: 50 }}>
        <h6>Displaying <b>{page_artists.length}</b> out of <b>{total_rows}</b> instances</h6>
        <div style={{ justifyContent: 'center' }}>
          <Grid container spacing={2} flexDirection={'row'} flexWrap={'wrap'}>
            {page_artists.map((data, index) => (
              <ArtistCard
                artist_id={data.artist_id}
                gender={data.gender}
                artist_name={data.artist_name}
                birthday={data.birthday}
                home_country={data.home_country}
                image_url={data.image_url}
                genre={data.genre}
                followers={data.followers}
                highlight_word={wordEntered}
              />
            ))}
          </Grid>
          <div style={{ display: 'flex', justifyContent: 'center', top: 20 }}>
            <AppPagination setInstances={p => setPageArtists(p)} setTotalCount={p => setTotalRows(p)} service={artist_page_service} generalRequestLink={fetch_url} inputPageSize={10} />
          </div>
        </div>
      </div>
    </div>
  );
}
export default Artists