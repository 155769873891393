import React from 'react'
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import { Link } from 'react-router-dom';
import {Grid} from "@mui/material";
import Highlighter from "react-highlight-words";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import './SongCard.css'

const ArtistCard = (props) => {

    let artist_name = props.artist_name
    let artist_id = props.artist_id
    let birthday = props.birthday
    let home_country = props.home_country[0].toUpperCase() + props.home_country.substring(1)
    let image_url = props.image_url
    let genre = props.genre[0].toUpperCase() + props.genre.substring(1)
    let followers = props.followers.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    let gender = props.gender[0].toUpperCase() + props.gender.substring(1)
    let highlight_word = props.highlight_word
  return (
    <Grid item xs={2.4}>
        <Card className='h-100' style={{ height: '20 rem', maxWidth: '30 rem', borderRadius:"10px"}} >
        <Card.Body>
        <Row>
                        <Col>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <Card.Img variant="top" src={image_url} className="card-img" style = {{width: 200, height: 200, objectFit: "cover"}}/>
                            </div>
                        </Col>
                    </Row>
          <Link to={`/artistdetail/${artist_id}`} style={{ textDecoration: 'none' }}>
            <i><Card.Title style = {{marginTop: 15}} >
              <Highlighter
               searchWords={[highlight_word]}
               autoEscape={true}
               textToHighlight={artist_name}
            />
              </Card.Title> </i>
          </Link>
          <Card.Text className='small'>
            <b>Gender: </b> 
            <Highlighter
                            searchWords={[highlight_word]}
                            autoEscape={true}
                            textToHighlight={gender}
                        /><br></br>
            <b>Birthday: </b>
            <Highlighter
                            searchWords={[highlight_word]}
                            autoEscape={true}
                            textToHighlight={birthday}
                        /> <br></br>
            <b>Home Country: </b>  <Highlighter
                            searchWords={[highlight_word]}
                            autoEscape={true}
                            textToHighlight={home_country}
                        /> <br></br>
            <b>Genre: </b> <Highlighter
                            searchWords={[highlight_word]}
                            autoEscape={true}
                            textToHighlight={genre}
                        /> <br></br>
            <b>Followers: </b> 
            <Highlighter
                            searchWords={[highlight_word]}
                            autoEscape={true}
                            textToHighlight={followers.toString()}
                        />  <br></br>
          </Card.Text>
          </Card.Body>
        </Card>
    </Grid>
  );
}

export default ArtistCard;