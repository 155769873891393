import { Helmet } from 'react-helmet';
import SongCard from '../components/SongCard';
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';
import React, { useState, useEffect } from 'react'
import AppPagination from "../components/AppPagination";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Card from 'react-bootstrap/Card';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Slider from '@mui/material/Slider';

import {
  Box,
  Grid,
  CardActionArea,
  Stack,
  Pagination,
  PaginationItem,
  CardContent,
  CardHeader,
  CardMedia,
  Typography,
} from "@mui/material";

import {
  songsJsonToObject, song_page_service
} from "../data/songData"

export function tokenizeSearch(input_str) {
  var api_string = input_str.toLowerCase().replace(/\s/g, '+')
  return api_string
}

const marksNumTracksRange = [
  {
    value: 1,
    label: '1',
  },
  {
    value: 8,
    label: '8',
  },
  {
    value: 16,
    label: '16',
  },
  {
    value: 24,
    label: '24',
  },
  {
    value: 32,
    label: '32',
  },
  {
    value: 40,
    label: '40',
  },
  {
    value: 48,
    label: '48',
  },
  {
    value: 56,
    label: '56',
  },
  {
    value: 64,
    label: '64',
  },
];

const Songs = () => {
  const [wordEntered, setWordEntered] = useState("");
  const [sortBy, setSortBy] = React.useState('song_name');
  const [orderBy, setOrderBy] = React.useState('+');
  const [filteredExplicitness, setFilteredExplicitness] = React.useState('All');
  const [filteredGenre, setFilteredGenre] = React.useState('All');
  const [numTracksRange, setNumTracksRange] = React.useState([1, 64]);

  const handleSearch = (event) => {
    const searchWord = event.target.value;
    setWordEntered(searchWord);
  };

  const clearInput = () => {
    setWordEntered("");
  };

  const api_test_host = "http://localhost:5003"
  const api_deploy_host = "https://api.concertfor.me"
  let api_host = api_deploy_host

  const [all_songs, setAllSongs] = useState([])
  const [fetch_url, setFetchUrl] = useState("")
  const [total_rows, setTotalRows] = useState(0);


  useEffect(() => {
    let url = `${api_host}/api/songs?`

    if (wordEntered !== "") {
      url += `search=${tokenizeSearch(wordEntered)}`
    }

    url = url + "&filterby=track_count+track_count"
    if (filteredExplicitness !== "All")
      url = url + "+explicit"
    if (filteredGenre !== "All")
      url = url + "+genre"


    url = url + `&fquery=>${numTracksRange[0]}+<${numTracksRange[1]}`
    if (filteredExplicitness !== "All")
      url = url + "+" + filteredExplicitness
    if (filteredGenre !== "All")
      url = url + "+" + filteredGenre

    if (sortBy !== "") {
      let sort_url = "&sortby="
      if (orderBy == "-") {
        sort_url = sort_url + "-"
      }
      sort_url = sort_url + sortBy
      url += sort_url
    }

    setFetchUrl(url)
  }, [wordEntered, orderBy, sortBy, filteredExplicitness, filteredGenre, numTracksRange])

  const [page_songs, setPageSongs] = useState([]);

  return (
    <div>Songs
      <Helmet>
        <title>Find Songs</title>
      </Helmet>
      <div style={{ marginTop: 70, display: "flex", justifyContent: "center", alignItems: "center" }}>
        <input style={{ borderRadius: "15px", height: "40px", fontSize: "15px", width: "200px", padding: "15px" }}
          type="text"
          placeholder=" Search for a song..."
          value={wordEntered}
          onChange={handleSearch}
        />
        <div style={{ padding: "10px" }}>
          {wordEntered === "" ? (
            <SearchIcon />
          ) : (
            <CloseIcon id="clearBtn" onClick={clearInput} />
          )}
        </div>

        <FormControl>
          <InputLabel style={{ marginBottom: "25" }}>Sort by</InputLabel>
          <Select
            style={{ width: "200px", backgroundColor: "white" }}
            label="Sort By"
            value={sortBy}
            onChange={(event) => {
              setSortBy(event.target.value);
            }}
          >
            <MenuItem value={"song_name"}>Song Name</MenuItem>
            <MenuItem value={"track_length"}>Track Length</MenuItem>
            <MenuItem value={"release_date"}>Release Date</MenuItem>
            <MenuItem value={"artists"}>Artist Name</MenuItem>
            <MenuItem value={"album"}>Album Name</MenuItem>
            <MenuItem value={"price"}>Track Price</MenuItem>
          </Select>
        </FormControl>

        {/* Order by menu */}
        <FormControl style={{ margin: "15px" }}>
          <InputLabel style={{ marginBottom: "25" }}>Order By</InputLabel>
          <Select
            style={{ width: "200px", backgroundColor: "white" }}
            label="Order by"
            value={orderBy}
            onChange={(event) => {
              setOrderBy(event.target.value);
            }}
          >
            <MenuItem value={"+"}>Ascending</MenuItem>
            <MenuItem value={"-"}>Descending</MenuItem>
          </Select>
        </FormControl>
        {/* </Card.Body>
        </Card> */}
      </div>

      <div style={{ display: "flex", marginTop: "10px", justifyContent: "center", alignItems: "center" }}>
        <FormControl style={{ margin: "10px" }}>
          <InputLabel style={{ marginBottom: "25" }}>Track Explicitness</InputLabel>
          <Select
            style={{ width: "200px", backgroundColor: "white" }}
            label="Track Explicitness"
            value={filteredExplicitness}
            onChange={(event) => {
              setFilteredExplicitness(event.target.value);
            }}
          >
            <MenuItem value={"All"}> All </MenuItem>
            <MenuItem value={"notExplicit"}> Clean </MenuItem>
            <MenuItem value={"explicit"}> Explicit </MenuItem>
          </Select>
        </FormControl>

        <div style={{ width: 300, marginLeft: 20, textAlign: 'center' }} >
          <p style={{ fontSize: 16, marginTop: 10 }}>Number of Tracks on Album</p>
          <Slider
            value={numTracksRange}
            onChangeCommitted={(event, newNumTracksRange) => {
              setNumTracksRange(newNumTracksRange);
            }}
            valueLabelDisplay="auto"
            step={4}
            min={1}
            max={64}
            marks={marksNumTracksRange}
          />
        </div>

        <FormControl style={{ margin: "25px" }}>
          <InputLabel style={{ marginBottom: "25" }}>Genre</InputLabel>
          <Select
            style={{ width: "150px", backgroundColor: "white" }}
            label="Genre"
            value={filteredGenre}
            onChange={(event) => {
              setFilteredGenre(event.target.value);
            }}
          >
            <MenuItem value={"All"}> All </MenuItem>
            <MenuItem value={"Soundtrack"}>Soundtrack</MenuItem>
            <MenuItem value={"Alternative"}>Alternative</MenuItem>
            <MenuItem value={"Pop"}>Pop</MenuItem>
            <MenuItem value={"Latino"}>Pop Latino</MenuItem>
            <MenuItem value={"Rock"}>Rock</MenuItem>
            <MenuItem value={"Country"}>Country</MenuItem>
            <MenuItem value={"Metal"}>Metal</MenuItem>
            <MenuItem value={"Hip-Hop/Rap"}>Hip-Hop & Rap</MenuItem>
            <MenuItem value={"Soul"}>R&B/Soul</MenuItem>
            <MenuItem value={"Latin"}>Latin</MenuItem>
            <MenuItem value={"Música Mexicana"}>Musicana Mexicana</MenuItem>
          </Select>
        </FormControl>

      </div>

      <div style={{ marginTop: 10 }}>
        <h6>Displaying <b>{page_songs.length}</b> out of <b>{total_rows}</b> instances</h6>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Grid container spacing={2} flexDirection={'row'} flexWrap={'wrap'}>
            {page_songs.map((data) => (
              <SongCard
                song_name={data.song_name}
                song_id={data.song_id}
                artists={data.artists}
                album={data.album}
                price={data.price}
                currency={data.currency}
                artwork_url={data.artwork_url}
                track_length={data.track_length}
                release_date={data.release_date}
                genre={data.genre}
                highlight_word={wordEntered}
              />
            ))}
          </Grid>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', top: 20 }}>
          <Stack>
            <AppPagination setInstances={p => setPageSongs(p)} setTotalCount={p => setTotalRows(p)} service={song_page_service} generalRequestLink={fetch_url} inputPageSize={10} />
          </Stack>
        </div>
      </div>
    </div>
  );
}

export default Songs