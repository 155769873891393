import { Box } from '@mui/material';
import SplashCards from '../components/SplashCards';
import Carousel from '../components/Carousel';
import { Helmet } from 'react-helmet';
import React, { useState, useEffect } from "react";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { artistsJsonToObject, artist_page_service } from "../data/artistData"
import { concertsJsonToObject, concert_page_service} from "../data/concertData"
import ArtistCard from '../components/ArtistCard';
import SongCard from '../components/SongCard';
import AppPagination from '../components/AppPagination';
import { Grid, Stack } from "@mui/material";
import {tokenizeSearch} from '../routes/Songs'
import ConcertCard from '../components/ConcertCard';
import {songsJsonToObject, song_page_service} from "../data/songData"
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import Embed from 'react-embed';

const Home = () => {

  return (
    <div>
      < Helmet >
        <title>Home</title>
      </Helmet >
      <Box>
        <Box>
          <Carousel />
        </Box>
        <div style={{display:"flex", justifyContent:"center", alignItems:"center", margin:"20px"}}>
          <Link to="/search" style={{ textDecoration: 'none' }}> 
            <Button variant="contained" size="large">Search</Button>
          </Link>
        </div>
        <div>
            <SplashCards />
        </div>
        <div style={{display:"flex", justifyContent:"center", alignItems:"center", margin:"20px", marginBottom:'50px'}}>
            <div style={{width: '50vw'}}> 
            <Embed url='https://youtu.be/FPmCq0AX_8c' />
            </div>
        </div>
      </Box>
    </div>
  )
}

export default Home;
