import { Box } from '@mui/material';
import SplashCards from '../components/SplashCards';
import Carousel from '../components/Carousel';
import { Helmet } from 'react-helmet';
import React, { useState, useEffect } from "react";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { artistsJsonToObject, artist_page_service } from "../data/artistData"
import { concertsJsonToObject, concert_page_service } from "../data/concertData"
import { tokenizeSearch } from '../routes/Songs'
import ArtistCard from '../components/ArtistCard';
import SongCard from '../components/SongCard';
import AppPagination from '../components/AppPagination';
import { Grid, Stack } from "@mui/material";
import ConcertCard from '../components/ConcertCard';
import { songsJsonToObject, song_page_service } from "../data/songData"
import { convertReleaseDate, millisToMinutesAndSeconds } from '../components/SongCard';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Link } from 'react-router-dom';
import Highlighter from "react-highlight-words";

const Search = () => {
  const [wordEntered, setWordEntered] = useState("");

  const handleSearch = (event) => {
    const searchWord = event.target.value;
    setWordEntered(searchWord);
  };

  const clearInput = () => {
    setWordEntered("");
  };

  const [all_concerts, setAllConcerts] = useState([])
  const [page_concerts, setPageConcerts] = useState([])
  const [all_artists, setAllArtists] = useState([])
  const [page_artists, setPageArtists] = useState([])
  const [all_songs, setAllSongs] = useState([])
  const [page_songs, setPageSongs] = useState([])

  const [fetch_url_artist, setFetchUrlArtist] = useState("")
  const [fetch_url_song, setFetchUrlSong] = useState("")
  const [fetch_url_concert, setFetchUrlConcert] = useState("")
  const [total_rows_artist, setTotalRowsArtist] = useState(0);
  const [total_rows_song, setTotalRowsSong] = useState(0);
  const [total_rows_concert, setTotalRowsConcert] = useState(0);


  const api_test_host = "http://localhost:5003"
  const api_deploy_host = "https://api.concertfor.me"
  let api_host = api_deploy_host

  useEffect(() => {

    let url_artist = `${api_host}/api/artists?`
    let url_song = `${api_host}/api/songs?`
    let url_concert = `${api_host}/api/concerts?`
    if (wordEntered !== "") {
      url_artist += `search=${tokenizeSearch(wordEntered)}`
      url_concert += `search=${tokenizeSearch(wordEntered)}`
      url_song += `search=${tokenizeSearch(wordEntered)}`
    }
    setFetchUrlArtist(url_artist)
    setFetchUrlSong(url_song)
    setFetchUrlConcert(url_concert)
  }, [wordEntered])

  return (
    <div style={{ marginTop: 80 }}>
      < Helmet >
        <title>Home</title>
      </Helmet >
      <Box>
        {/* render search box */}
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "50px" }}>
          <input style={{ borderRadius: "15px", height: "50px", fontSize: "15px", width: "600px", padding: "15px" }}
            type="text"
            placeholder=" Enter a key word..."
            value={wordEntered}
            onChange={handleSearch}
          />
          <div style={{ padding: "10px" }}>
            {wordEntered === "" ? (
              <SearchIcon />
            ) : (
              <CloseIcon id="clearBtn" onClick={clearInput} />
            )}
          </div>
        </div>

        {/* render song results */}
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
          <h1><b> Songs </b></h1>
        </div>
        <div style={{ marginTop: 20 }}>
          <div>
            <h6>Displaying <b>{page_songs.length}</b> out of <b>{total_rows_song}</b> results</h6>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Grid container spacing={2} flexDirection={'row'} flexWrap={'wrap'}>
                {page_songs.map((data) => (
                  <SongCard
                    song_name={data.song_name}
                    song_id={data.song_id}
                    artists={data.artists}
                    album={data.album}
                    price={data.price}
                    currency={data.currency}
                    artwork_url={data.artwork_url}
                    track_length={data.track_length}
                    release_date={data.release_date}
                    genre={data.genre}
                    highlight_word={wordEntered}
                  />
                ))}
              </Grid>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <AppPagination setInstances={p => setPageSongs(p)} setTotalCount={p => setTotalRowsSong(p)} service={song_page_service} generalRequestLink={fetch_url_song} inputPageSize={10} />
            </div>
          </div>
        </div>

        {/* render artist results */}
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>
          <h1><b> Artists </b></h1>
        </div>
        <div style={{ marginTop: 20 }}>
          <div>
            <h6>Displaying <b>{page_artists.length}</b> out of <b>{total_rows_artist}</b> results</h6>
            <div style={{ justifyContent: 'center' }}>
              <Grid container spacing={1} flexDirection={'row'} flexWrap={'wrap'}>
                {page_artists.map((data, index) => (
                  <ArtistCard
                    artist_id={data.artist_id}
                    gender={data.gender}
                    artist_name={data.artist_name}
                    birthday={data.birthday}
                    home_country={data.home_country}
                    image_url={data.image_url}
                    genre={data.genre}
                    followers={data.followers}
                    highlight_word={wordEntered}
                  />
                ))}
              </Grid>
              <div style={{ display: 'flex', justifyContent: 'center', top: 20 }}>
                <AppPagination setInstances={p => setPageArtists(p)} setTotalCount={p => setTotalRowsArtist(p)} service={artist_page_service} generalRequestLink={fetch_url_artist} inputPageSize={10} />
              </div>
            </div>
          </div>
        </div>

        {/* render concert results */}
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>
          <h1><b> Concerts </b></h1>
        </div>
        <div style={{ marginTop: 20 }}>
          <div>
            <h6>Displaying <b>{page_concerts.length}</b> out of <b>{total_rows_concert}</b> results</h6>
            <div style={{ justifyContent: 'center' }}>
              <Grid container spacing={2} flexDirection={'row'} flexWrap={'wrap'}>
                {page_concerts.map((data) => (
                  <ConcertCard
                    event_name={data.event_name}
                    event_description={data.event_description}
                    venue_name={data.venue_name}
                    venue_address={data.venue_address}
                    attendance={data.predicted_attendance}
                    artists={data.artist_name}
                    start_date={data.start_date}
                    start_time={data.start_time}
                    time_zone={data.time_zone}
                    img={data.image_url}
                    instanceKey={data.concert_id}
                    highlight_word={wordEntered}
                  />
                ))}
              </Grid>
              <div style={{ display: 'flex', justifyContent: 'center', top: 20 }}>
                <AppPagination setInstances={p => setPageConcerts(p)} setTotalCount={p => setTotalRowsConcert(p)} service={concert_page_service} generalRequestLink={fetch_url_concert} inputPageSize={10} />
              </div>
            </div>
          </div>
        </div>
      </Box>
    </div>
  );
};

export default Search;