import React from 'react'
import { about_apis } from '../data/aboutData';
import APICard from './APICard';
import ListGroup from 'react-bootstrap/ListGroup';
import { Grid } from "@mui/material";



const ListApiCards = () => {
    return (
        <Grid container spacing={1} columns={12} pb={8}>
            {about_apis.map((data, index) => {
                return (
                    <Grid item xs={3} key={index}>
                        <APICard
                            name={data.name}
                            use={data.use}
                            howItWasScraped={data.howItWasScraped}
                            photo={data.photo}
                            link={data.link}
                        />
                    </Grid>
                );
            })}
        </Grid>
    )
}

export default ListApiCards