import React from "react";
import { Pie } from "react-chartjs-2";
import Chart from "chart.js/auto"

export const PieChartJS = ({ xLabels, dataSetLabel, yData }) => {
    const data = {
        labels: xLabels,
        datasets: [
            {
                label: dataSetLabel,
                backgroundColor: ['rgba(0, 116, 217, .2)', 'rgba(255, 65, 54, .2)', 'rgba(46, 204, 64, .2)', 'rgba(255, 133, 27, .2)', 'rgba(127, 219, 255, .2)', 'rgba(177, 13, 201, .2)', 'rgba(255, 220, 0, .2)', 'rgba(0, 31, 63, .2)', 'rgba(57, 204, 204, .2)', 'rgba(1, 255, 112, .2)', 'rgba(133, 20, 75, .2)', 'rgba(240, 18, 190, .2)', 'rgba(61, 153, 112, .2)', 'rgba(17, 17, 17, .2)', 'rgba(170, 170, 170, .2)'],
                borderColor: ['rgba(0, 116, 217, 1)', 'rgba(255, 65, 54, 1)', 'rgba(46, 204, 64, 1)', 'rgba(255, 133, 27, 1)', 'rgba(127, 219, 255, 1)', 'rgba(177, 13, 201, 1)', 'rgba(255, 220, 0, 1)', 'rgba(0, 31, 63, 1)', 'rgba(57, 204, 204, 1)', 'rgba(1, 255, 112, 1)', 'rgba(133, 20, 75, 1)', 'rgba(240, 18, 190, 1)', 'rgba(61, 153, 112, 1)', 'rgba(17, 17, 17, 1)', 'rgba(170, 170, 170, 1)'],
                data: yData,
                borderWidth: 1,
            },
        ],
    };


    return (
        < div className="pie-chart">
            <Pie data={data} />
        </div >
    );
};

export default PieChartJS;