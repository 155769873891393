import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom/client';
import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';
import AppPagination from "./AppPagination";
import { song_service } from "../data/songData"
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import { Link } from 'react-router-dom';
import Attribute from './Attribute'
import './SongCard.css';
import {
    Box,
    Grid,
    CardActionArea,
    Stack,
    Pagination,
    PaginationItem,
    CardContent,
    CardHeader,
    CardMedia,
    Typography,
} from "@mui/material";
import Highlighter from "react-highlight-words";

import {
    songsJsonToObject, song_page_service
} from "../data/songData"

export function convertReleaseDate(releaseDate) {
    var release_date = new String(releaseDate);
    var year = release_date.slice(0, 4);
    var month = release_date.slice(5, 7);
    var day = release_date.slice(8, 10);
    var total_string = month + "/" + day + "/" + year;
    return total_string
}

// https://stackoverflow.com/questions/21294302/converting-milliseconds-to-minutes-and-seconds-with-javascript
export function millisToMinutesAndSeconds(millis_str) {
    var millis = parseInt(millis_str)
    var minutes = Math.floor(millis / 60000);
    var seconds = ((millis % 60000) / 1000).toFixed(0);
    return minutes + ":" + (seconds < 10 ? '0' : '') + seconds;
}

const SongCard = (props) => {
    let song_name = props.song_name
    let song_id = props.song_id
    let artists = props.artists
    let album = props.album
    let price = props.price
    let currency = props.currency
    let artwork_url = props.artwork_url
    let track_length = props.track_length
    let release_date = props.release_date
    let genre = props.genre
    let highlight_word = props.highlight_word

    return (
        <Grid item xs={2.4}>
            <Card className='h-100' style={{ height: '20 rem', maxWidth: '30 rem', borderRadius: "10px" }}>
                <Card.Body>
                    <Row>
                        <Col>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <Card.Img variant="top" src={artwork_url} className="card-img" />
                            </div>
                        </Col>
                    </Row>
                    <Card.Title style={{ marginTop: 15 }}> <Link to={"/songs/" + song_id} style={{ textDecoration: 'none' }}>
                        <i>
                            <Highlighter
                                searchWords={[highlight_word]}
                                autoEscape={true}
                                textToHighlight={song_name}
                            />
                        </i>
                    </Link></Card.Title>
                    <Card.Text className='small'>
                        <b> Artist: </b>
                        <Highlighter
                            searchWords={[highlight_word]}
                            autoEscape={true}
                            textToHighlight={artists}
                        /> <br></br>
                        <b>Album: </b>
                        <Highlighter
                            searchWords={[highlight_word]}
                            autoEscape={true}
                            textToHighlight={album}
                        /> <br></br>
                        <b>Genre: </b>
                        <Highlighter
                            searchWords={[highlight_word]}
                            autoEscape={true}
                            textToHighlight={genre} />
                        <br></br>
                        <b>Release Date: </b>
                        <Highlighter
                            searchWords={[]}
                            autoEscape={true}
                            textToHighlight={convertReleaseDate(release_date)} />
                        <br></br>
                        <b>Song Length: </b>
                        <Highlighter
                            searchWords={[]}
                            autoEscape={true}
                            textToHighlight={millisToMinutesAndSeconds(track_length)}
                        />
                        <br></br>
                        <b>Track Price: </b>
                        <Highlighter
                            searchWords={[]}
                            autoEscape={true}
                            textToHighlight={price + " " + currency}
                        />
                        <br></br>


                    </Card.Text>
                </Card.Body>
            </Card>
        </Grid>
    );
}

export default SongCard