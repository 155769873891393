import React, { useState, useEffect } from 'react'
import ListOfIndividualAbout from '../components/ListOfIndividualAbout'
import Container from 'react-bootstrap/Container';

import { Helmet } from 'react-helmet';
import axios from 'axios';
import { Outlet } from 'react-router-dom'
import styles from './About.module.css'
import { dev_about_list } from '../data/aboutData';
import Button from 'react-bootstrap/Button';
import { StatisticsCall, ContributorInfo, getTotalCommits, statsCallResponseToTotalNumIssues } from '../utils/GitLabUtils';
import ListApiCards from '../components/ListApiCards';
import ListToolCards from '../components/ListToolCards';
import Badge from 'react-bootstrap/Badge';
import ListGroup from 'react-bootstrap/ListGroup';


function getTotalUnitTests() {
    let sum = 0
    dev_about_list.map((data, index) => {
        sum += data.unitTestsCount
    })
    return sum
}

const About = () => {

    const [totalNumIssues, setTotalNumIssues] = useState(0)
    useEffect(() => {
        fetch('https://gitlab.com/api/v4/projects/39617716/issues_statistics')
            .then(function (response) {
                return response.text();
            })
            .then(function (myJson) {
                setTotalNumIssues(statsCallResponseToTotalNumIssues(myJson))
            });
    }, [])

    const [contributorInfoList, setContributorInfo] = useState([] as ContributorInfo[])
    useEffect(() => {
        fetch('https://gitlab.com/api/v4/projects/39617716/repository/contributors')
            .then(function (response) {
                return response.text();
            })
            .then(function (myJson) {
                let contributorInfoList: ContributorInfo[] = JSON.parse(myJson)
                setContributorInfo(contributorInfoList)
            });
    }, [])



    return (
        <div style={{ marginTop: 65 }} >

            < Helmet >
                <title>About</title>
            </Helmet >
            <div className="container text-center">
                <div className="row gx-3" style={{ marginTop: 30 }}>
                    <div className="col">
                        <h2 data-testid="header-1"> What is Concertfor.me?</h2>
                        <p style={{ fontSize: 20 }} >
                            Concertfor.me is an amazing website which can help you find concerts for your favorite
                            artists, or from artists that are similar to ones that you like.
                            It's the perfect tool to discover new music and experience it live!
                            It's intended for anyone who wants to find new artists, music, or
                            live concerts to attend.
                        </p>
                    </div>
                    <div className="col">
                        <h2 data-testid="header-2">Interesting result of integrating disparate data</h2>
                        <p style={{ fontSize: 20 }}>
                            Integrating disparate data resulted in having the ability to find and discover new artists/music as well as
                            find concerts for them all in one place. This is a common use case
                            for people who like live music.
                        </p>
                    </div>
                </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <h1 style={{ marginTop: "30px" }}> Our Dev Team </h1></div>

            <Container style={{ width: "100%", marginTop: 20 }}>
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: "30px" }}><ListOfIndividualAbout
                listOfContributorInfo={contributorInfoList}
            /></div>
            </Container>


            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <h1 style={{ marginTop: "20px" }}> Team Git Stats</h1> </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <ListGroup as="ol" numbered style={{ width: "20%", padding: "10px", marginLeft: "20px" }}>
                    <ListGroup.Item
                        as="li"
                        className="d-flex justify-content-between align-items-start"
                    >
                        <div className="ms-2 me-auto">
                            <div className="fw-bold">Total Commits:</div>
                        </div>
                        <Badge bg="primary" pill>
                            {getTotalCommits(contributorInfoList)}
                        </Badge>
                    </ListGroup.Item>
                    <ListGroup.Item
                        as="li"
                        className="d-flex justify-content-between align-items-start"
                    >
                        <div className="ms-2 me-auto">
                            <div className="fw-bold">Total Issues:</div>
                        </div>
                        <Badge bg="primary" pill>
                            {totalNumIssues}
                        </Badge>
                    </ListGroup.Item>
                    <ListGroup.Item
                        as="li"
                        className="d-flex justify-content-between align-items-start"
                    >
                        <div className="ms-2 me-auto">
                            <div className="fw-bold">Total UnitTests:</div>
                        </div>
                        <Badge bg="primary" pill>
                            {getTotalUnitTests()}
                        </Badge>
                    </ListGroup.Item>
                </ListGroup>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: "30px" }}>
                <a href="https://documenter.getpostman.com/view/23628931/2s83mjEg7x" target="_blank" rel="noreferrer">
                    <Button variant="primary">
                        <h1> Postman Documentation </h1>
                    </Button>
                </a>
            </div>

            <div style={{ display: 'flex', justifyContent: 'center', marginTop: "30px" }}>             <h1>APIs and Resources Used</h1></div>

            <Container style={{ width: "100%", marginTop: 20 }}>
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: "30px" }}><ListApiCards /></div>
            </Container>

            <div style={{ display: 'flex', justifyContent: 'center' }}>             <h1 style={{ marginTop: "30px" }}>Tools used</h1></div>

            <Container style={{ width: "100%", marginTop: 20 }}>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <ListToolCards />
                </div>
            </Container>

        </div >
    )
}

export default About