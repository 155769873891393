import React, { useState, useEffect } from 'react'
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import axios from 'axios';
import { StatisticsCall } from '../utils/GitLabUtils'


//Name, photo, bio, major responsibilities (ex: frontend vs backend team), # of commits, issues, and unit tests contributed of each team member


const IndividualAbout = (props: any) => {
    let name = props.name
    let gitLabName = props.gitLabName
    let photo = props.photo
    let bio = props.bio
    let resonsibilities = props.responsibilities
    let commitsCount = props.commitsCount
    let unitTestsCount = props.unitTestsCount
    let gitLabId = props.gitLabId

    const [issuesCount, setIssuesCount] = useState(0)
    useEffect(() => {
        fetch('https://gitlab.com/api/v4/projects/39617716/issues_statistics?assignee_username=' + gitLabId).then(function (response) {
            return response.text();
        })
            .then(function (myJson) {
                let stats: StatisticsCall = JSON.parse(myJson)
                let result = stats.statistics.counts.all
                setIssuesCount(result)
            });
    }, [])

    return (
                <Card style={{ width: '14rem' }}>
                    <Card.Img variant="top" src={photo} />
                    <Card.Body>
                        <Card.Title>{name}</Card.Title>
                        <Card.Text>
                            Bio: {bio}  <br></br>
                            Responsibilities: {resonsibilities} <br></br>
                            CommitsCount: {commitsCount} <br></br>
                            IssuesCount: {issuesCount} <br></br>
                            UnitTestsCount: {unitTestsCount} <br></br>

                        </Card.Text>
                    </Card.Body>
                </Card>
    )
}

export default IndividualAbout