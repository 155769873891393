import {useState, useEffect} from 'react'
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import SpotifyPlayer from 'react-spotify-player';
import { ListGroupItem, Card,ListGroup } from 'react-bootstrap';
import axios from 'axios';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

export default function ArtistDetail(props: any) {

  const params = useParams()

  let artist_id = (params['artist_id'])
  let fetch_url = `https://api.concertfor.me/api/artists?id=${artist_id}`

  const [artist, setArtist] = useState([])
  const [song, setSong] = useState([])
  const [concert, setConcert] = useState([])
  
  useEffect(() => {
    axios
      .get(fetch_url)
      .then(res => {
        console.log(res)
        setArtist(res.data['artists'][0])
        setConcert(res.data['concerts'][0])
        setSong(res.data['songs'][0])
      })
      .catch(err => {
        console.log(err)
      })
  },[])
  
  let artist_name = artist['artist_name']
  let aliases = artist['aliases']
  let birthday = artist['birthday']
  var home_country = artist['home_country']
  let followers = artist['followers']//.toLocaleString("en-US")
  let gender = artist['gender']
  let genre = artist['genre']

  let image_url = artist['image_url']
  let popularity = artist['popularity']
  let spotify_id = artist['spotify_id']
  let spotify_uri = `spotify:artist:${spotify_id}`
  var hasRelatedSong = true
  var hasRelatedConcert = true

  if (typeof song == 'undefined' || song.length === 0) {
    hasRelatedSong = false
  }
  if (typeof concert == 'undefined' || concert.length === 0) {
    hasRelatedConcert = false
  }

  return (
    <Container style ={{marginTop:100, display: 'flex', justifyContent: 'center'}}>
      <Helmet>
        <title>Artist Detail</title>
      </Helmet>
      <Row>
        <Col> 
        <Card style={{ width: '50rem' }}>
          <Card.Img variant="top" src={image_url} />
          <Card.Body>
            <Card.Title>{artist_name}</Card.Title>
            <Card.Text>
              <b> Birthday:</b>  {birthday} <br></br>
              <b> Gender:</b> {gender} <br></br>
              <b> Location:</b>  {home_country} <br></br>
              <b> Genre:</b> {genre} <br></br>
              <b> Followers:</b> {followers?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}  <br></br>
              <b> Popularity :</b> {popularity} <br></br>
              <b> Other names:</b> {aliases}  <br></br>
            </Card.Text>
            <div>
            {hasRelatedSong ? <Card.Text><Link to={`/songs/${song[0]}`}>Related songs: {song[1]}</Link></Card.Text>
              : <Card.Text>Not found any related song</Card.Text>
            }
            </div>
            <div>
            {hasRelatedConcert ? <Card.Text><Link to={`/concerts/${concert[0]}`}>Related concerts: {concert[1]}</Link></Card.Text>
              : <Card.Text>Not found any related concert</Card.Text>
            }
            </div>
          </Card.Body>
        </Card>
        </Col>
        <Col>
        <Card style={{ width: '20rem' }}>
            <Card.Title style ={{display: 'flex', justifyContent: 'center', marginTop: '20px'}}>
                Artist Discography on Spotify
            </Card.Title>
        <Card.Body>
        <div style ={{display: 'flex', justifyContent: 'center'}}>
        <SpotifyPlayer
          uri={spotify_uri}
          size="large"
          them="black"
          view="list"
        /> </div>
        </Card.Body>
      </Card >
        </Col>
        </Row>
    </Container>
  );
}
