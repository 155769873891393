import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet';
import ConcertCard from '../components/ConcertCard';
import ListGroup from 'react-bootstrap/ListGroup';
import Button from 'react-bootstrap/Button';
import AppPagination from "../components/AppPagination";
import {
  Box,
  Grid,
  CardActionArea,
  Stack,
  Pagination,
  PaginationItem,
  CardContent,
  CardHeader,
  CardMedia,
  Typography,
} from "@mui/material";

import {
  concertsJsonToObject, concert_page_service
} from "../data/concertData"
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";

const Concerts = () => {

  const [wordEntered, setWordEntered] = useState("");

  const handleSearch = (event) => {
    const searchWord = event.target.value;
    setWordEntered(searchWord);
  };

  const clearInput = () => {
    setWordEntered("");
  };

  // for sorting attribute
  const [sortBy, setSortBy] = React.useState("start_date");
  const [orderBy, setOrderBy] = React.useState("+");

  //for filter attribute
  const [filteredTimezone, setFilteredTimezone] = React.useState("All");



  const api_test_host = "http://localhost:5003"
  const api_deploy_host = "https://api.concertfor.me"
  let api_host = api_deploy_host


  const [fetch_url, setFetchUrl] = useState("")
  const [total_rows, setTotalRows] = useState(0);



  useEffect(() => {

    let url = `${api_host}/api/concerts?`

    if (wordEntered !== "") {
      url += `search=${wordEntered.toLowerCase()}`
    }

    if (filteredTimezone !== "All") {
      url = url + "&filterby=time_zone&fquery=" + filteredTimezone
    }

    url = url + "&sortby=" + orderBy + sortBy

    setFetchUrl(url)

  }, [wordEntered, filteredTimezone, sortBy, orderBy])


  const [page_concerts, setPageConcerts] = useState([]);

  return (
    <div> Concerts
      < Helmet >
        <title>Find Concerts</title>
      </Helmet >

      <div style={{ marginTop: 70, display: "flex", justifyContent: "center", alignItems: "center" }}>
        <input style={{ borderRadius: "15px", height: "40px", fontSize: "15px", width: "200px", padding: "15px" }}
          type="text"
          placeholder=" Enter a keyword..."
          value={wordEntered}
          onChange={handleSearch}
        />
        <div style={{ margin: "15px" }}>
          {wordEntered === "" ? (
            <SearchIcon />
          ) : (
            <CloseIcon id="clearBtn" onClick={clearInput} />
          )}
        </div>

        {/*Sort by menu */}
        <FormControl style={{ margin: "15px" }}>
          <InputLabel style={{ marginBottom: "25" }}>Sort by</InputLabel>
          <Select
            style={{ width: "200px", backgroundColor: "white" }}
            label="Sort By"
            value={sortBy}
            onChange={(event) => {
              setSortBy(event.target.value);
            }}
          >
            <MenuItem value={"artist_name"}>Name</MenuItem>
            <MenuItem value={"predicted_attendance"}>Attendance Capacity</MenuItem>
            <MenuItem value={"start_date"}>Start Date</MenuItem>
            <MenuItem value={"start_time"}>Start Time</MenuItem>
            <MenuItem value={"venue_name"}>Venue Name</MenuItem>
          </Select>
        </FormControl>

        {/* Order by menu */}
        <FormControl style={{ margin: "15px" }}>
          <InputLabel style={{ marginBottom: "25" }}>Order by</InputLabel>
          <Select
            style={{ width: "200px", backgroundColor: "white" }}
            label="Order By"
            value={orderBy}
            onChange={(event) => {
              setOrderBy(event.target.value);
            }}
          >
            <MenuItem value={"+"}>Ascending</MenuItem>
            <MenuItem value={"-"}>Descending</MenuItem>
          </Select>
        </FormControl>

        {/* Filter Timezone menu */}
        <FormControl style={{ margin: "15px" }}>
          <InputLabel style={{ marginBottom: "25" }}>Timezone</InputLabel>
          <Select
            style={{ width: "280px", backgroundColor: "white" }}
            label="Timezone"
            value={filteredTimezone}
            onChange={(event) => {
              setFilteredTimezone(event.target.value);
            }}
          >
            <MenuItem value={"All"}>All</MenuItem>
            <MenuItem value={"America/Winnipeg"}>America/Winnipeg</MenuItem>
            <MenuItem value={"America/New_York"}>America/New York</MenuItem>
            <MenuItem value={"America/Detroit"}>America/Detroit</MenuItem>
            <MenuItem value={"America/Indiana/Indianapolis"}>America/Indiana/Indianapolis</MenuItem>
            <MenuItem value={"America/Los_Angeles"}>America/Los Angeles</MenuItem>
            <MenuItem value={"America/Chicago"}>America/Chicago</MenuItem>
            <MenuItem value={"America/Toronto"}>America/Toronto</MenuItem>
            <MenuItem value={"America/Vancouver"}>America/Vancouver</MenuItem>
            <MenuItem value={"America/Edmonton"}>America/Edmonton</MenuItem>
            <MenuItem value={"America/Kentucky/Louisville"}>America/Kentucky/Louisville</MenuItem>
            <MenuItem value={"America/Phoenix"}>America/Phoenix</MenuItem>
            <MenuItem value={"America/Denver"}>America/Denver</MenuItem>
            <MenuItem value={"Europe/London"}>Europe/London</MenuItem>
          </Select>
        </FormControl>

      </div>

      <div style={{ marginTop: 15 }}>
        <h6>Displaying <b>{page_concerts.length}</b> out of <b>{total_rows}</b> instances</h6>
        <div style={{ justifyContent: 'center' }}>
          <Grid container spacing={2} flexDirection={'row'} flexWrap={'wrap'}>
            {page_concerts.map((data) => (

              <ConcertCard
                event_name={data.event_name}
                event_description={data.event_description}
                venue_name={data.venue_name}
                venue_address={data.venue_address}
                attendance={data.predicted_attendance}
                artists={data.artist_name}
                start_date={data.start_date}
                start_time={data.start_time}
                time_zone={data.time_zone}
                img={data.image_url}
                instanceKey={data.concert_id}
                highlight_word={wordEntered}
              />
            ))}
          </Grid>
          <div style={{ display: 'flex', justifyContent: 'center', top: 20 }}>
            <AppPagination setInstances={p => setPageConcerts(p)} setTotalCount={p => setTotalRows(p)} service={concert_page_service} generalRequestLink={fetch_url} inputPageSize={10} />
          </div>

        </div>
      </div>
    </div >
  );
}
export default Concerts