
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import './ConcertCard.css';
import {
    Box,
    Grid,
    Card,
    CardActionArea,
    Stack,
    Pagination,
    PaginationItem,
    CardContent,
    CardHeader,
    CardMedia,
    Typography,
} from "@mui/material";
import Highlighter from "react-highlight-words";



const ConcertCard = (props) => {

    let event_name = props.event_name
    let venue_name = props.venue_name
    let venue_address = props.venue_address
    let attendance = props.attendance
    let artists = props.artists
    let start_date = props.start_date
    let start_time = props.start_time
    let time_zone = props.time_zone
    let img = props.img
    let instanceKey = props.instanceKey
    let highlight_word = props.highlight_word

    return (
        <Grid item xs={2.4} key={instanceKey}>
            <Card className='concert-card' key={instanceKey}>
                <CardMedia
                    component='img'
                    height='33%'
                    image={img}
                />
                <CardContent className='concert-card'>
                    <Link to={"/concerts/" + instanceKey} style={{ textDecoration: 'none' }}>
                        <Typography variant='body1' component='div'>
                        <Highlighter
                            searchWords={[highlight_word]}
                            autoEscape={true}
                            textToHighlight={event_name}
                        />
                        </Typography>
                    </Link>

                    <Typography variant='body2' color='text.secondary'>
                        
                        <b>Venue:</b>  
                        <Highlighter
                            searchWords={[highlight_word]}
                            autoEscape={true}
                            textToHighlight={venue_name}
                        />
                        <br></br>
                        <b>Address:</b> <Highlighter
                            searchWords={[highlight_word]}
                            autoEscape={true}
                            textToHighlight={venue_address}
                        /> <br></br>
                        <b>Attendance:</b> <Highlighter
                            searchWords={[highlight_word]}
                            autoEscape={true}
                            textToHighlight={attendance.toString()}
                        />
                          <br></br>
                        <b>Artists:</b> 
                        <Highlighter
                            searchWords={[highlight_word]}
                            autoEscape={true}
                            textToHighlight={artists}
                        />
                        <br></br>
                        <b>Date:</b> <Highlighter
                            searchWords={[highlight_word]}
                            autoEscape={true}
                            textToHighlight={start_date}
                        />  <br></br>
                        <b>Time:</b> <Highlighter
                            searchWords={[highlight_word]}
                            autoEscape={true}
                            textToHighlight={start_time}
                        />  <br></br>
                        <b>Timezone:</b> 
                        <Highlighter
                            searchWords={[highlight_word]}
                            autoEscape={true}
                            textToHighlight={time_zone}
                        />  
                    </Typography>
                </CardContent>
            </Card>
        </Grid >

    );
}


export default ConcertCard;