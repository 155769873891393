export interface Artist {
    aliases: string;
    artist_id: number;
    artist_name: string;
    birthday: string;
    followers: number;
    gender: string;
    genre: string;
    home_country: string;
    image_url: string;
    popularity: number;
    spotify_id: string;
    spotify_page_url: string;
}


export interface ArtistResponse {
    artists: Artist[][];
    concerts: any[];
    songs: any[][];
}
export interface ArtistsResponse {
    artists: Artist[];
    length: number;
    total_rows: number;
}

export const artist_page_service = {
    getData: ({ from, to, requestLink }) => {
        let n = to - from
        requestLink += `&n=${n}&offset=${from}`
        return new Promise((resolve) => {
            fetch(requestLink)
                .then(function (response) {
                    return response.text();
                })
                .then(function (myJson) {
                    let artistResponse = artistsJsonToObject(myJson)
                    let artists = artistResponse.artists
                    resolve({
                        count: artistResponse.total_rows,
                        data: artists
                    })
                });
        });
    }
}

export function artistsJsonToObject(json: string): ArtistsResponse {
    let result: ArtistsResponse = JSON.parse(json)
    return result;
}

export function artistJsonToObject(json: string): ArtistResponse {
    let result: ArtistResponse = JSON.parse(json)
    return result;
}
