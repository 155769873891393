import React from 'react'
import './NavModule.css'
import { Nav, Navbar } from 'react-bootstrap'
import logo from '../assets/mic3.png';

const Navb: React.FC<{}> = () => {
    return (
        <div className='navb'>
            <Navbar className='navbar-color' variant='light' fixed='top' expand="lg">
                <Navbar.Brand style={{color: 'white'}}>
                    <img src={logo} width="40px" height="40px" />
                    Concertfor.me
                </Navbar.Brand>
                <Nav>
                    <Nav.Link href="/" style={{color: 'white'}}>Home </Nav.Link>
                    <Nav.Link href="/about" style={{color: 'white'}}>About</Nav.Link>
                    <Nav.Link href="/search" style={{color: 'white'}}>Search</Nav.Link>
                    <Nav.Link href="/concerts" style={{color: 'white'}}>Concerts</Nav.Link>
                    <Nav.Link href="/artists" style={{color: 'white'}}>Artists</Nav.Link>
                    <Nav.Link href="/songs" style={{color: 'white'}}>Songs</Nav.Link>
                    <Nav.Link href="/visualizations" style={{color: 'white'}}>Visualizations</Nav.Link>
                    <Nav.Link href="/providervisualizations" style={{color: 'white'}}>Provider Visualizations</Nav.Link>
                </Nav>
            </Navbar>
        </div>

    )
}

export default Navb