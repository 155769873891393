import img1 from "../assets/song_phase1/harry.jpg"
import img2 from "../assets/song_phase1/rockstar.jpg"
import img3 from "../assets/song_phase1/happier.jpg"

export const song_list = [
    {
        id: 0,
        "wrapperType": "track",
        "kind": "song",
        "artistId": 471260289,
        "collectionId": 1226034336,
        "trackId": 1226034393,
        "artistName": "Harry Styles",
        "collectionName": "Harry Styles",
        "trackName": "Sign of the Times",
        "collectionCensoredName": "Harry Styles",
        "trackCensoredName": "Sign of the Times",
        "artistViewUrl": "https://music.apple.com/us/artist/harry-styles/471260289?uo=4",
        "collectionViewUrl": "https://music.apple.com/us/album/sign-of-the-times/1226034336?i=1226034393&uo=4",
        "trackViewUrl": "https://music.apple.com/us/album/sign-of-the-times/1226034336?i=1226034393&uo=4",
        "previewUrl": "https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/19/f7/99/19f799a3-4638-f354-8713-f5ac076f328e/mzaf_2398941441794619302.plus.aac.p.m4a",
        "artworkUrl30": "https://is4-ssl.mzstatic.com/image/thumb/Music124/v4/3d/5e/aa/3d5eaaa3-9a86-c264-5cd5-7fac83f99a59/886446451978.jpg/30x30bb.jpg",
        "artworkUrl60": "https://is4-ssl.mzstatic.com/image/thumb/Music124/v4/3d/5e/aa/3d5eaaa3-9a86-c264-5cd5-7fac83f99a59/886446451978.jpg/60x60bb.jpg",
        "artworkUrl100": "https://is4-ssl.mzstatic.com/image/thumb/Music124/v4/3d/5e/aa/3d5eaaa3-9a86-c264-5cd5-7fac83f99a59/886446451978.jpg/100x100bb.jpg",
        "collectionPrice": 9.99,
        "trackPrice": 1.29,
        "releaseDate": "2017-04-07T07:00:00Z",
        "collectionExplicitness": "notExplicit",
        "trackExplicitness": "notExplicit",
        "discCount": 1,
        "discNumber": 1,
        "trackCount": 10,
        "trackNumber": 2,
        "trackTimeMillis": 340707,
        "country": "USA",
        "currency": "USD",
        "primaryGenreName": "Pop",
        "isStreamable": false
    },
    {
        id: 1,
        "wrapperType": "track",
        "kind": "song",
        "artistId": 966309175,
        "collectionId": 1373516902,
        "trackId": 1373516920,
        "artistName": "Post Malone",
        "collectionName": "beerbongs & bentleys",
        "trackName": "rockstar (feat. 21 Savage)",
        "collectionCensoredName": "beerbongs & bentleys",
        "trackCensoredName": "rockstar (feat. 21 Savage)",
        "artistViewUrl": "https://music.apple.com/us/artist/post-malone/966309175?uo=4",
        "collectionViewUrl": "https://music.apple.com/us/album/rockstar-feat-21-savage/1373516902?i=1373516920&uo=4",
        "trackViewUrl": "https://music.apple.com/us/album/rockstar-feat-21-savage/1373516902?i=1373516920&uo=4",
        "previewUrl": "https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/10/c1/3d/10c13d42-fd93-9b85-2576-dcdf3d65ef76/mzaf_1879447237147750935.plus.aac.p.m4a",
        "artworkUrl30": "https://is4-ssl.mzstatic.com/image/thumb/Music125/v4/7e/3c/4e/7e3c4ef6-daa7-cc10-57d0-45f5a562eaf5/18UMGIM22101.rgb.jpg/30x30bb.jpg",
        "artworkUrl60": "https://is4-ssl.mzstatic.com/image/thumb/Music125/v4/7e/3c/4e/7e3c4ef6-daa7-cc10-57d0-45f5a562eaf5/18UMGIM22101.rgb.jpg/60x60bb.jpg",
        "artworkUrl100": "https://is4-ssl.mzstatic.com/image/thumb/Music125/v4/7e/3c/4e/7e3c4ef6-daa7-cc10-57d0-45f5a562eaf5/18UMGIM22101.rgb.jpg/100x100bb.jpg",
        "collectionPrice": 9.99,
        "trackPrice": 1.29,
        "releaseDate": "2017-09-15T07:00:00Z",
        "collectionExplicitness": "explicit",
        "trackExplicitness": "explicit",
        "discCount": 1,
        "discNumber": 1,
        "trackCount": 18,
        "trackNumber": 6,
        "trackTimeMillis": 218146,
        "country": "USA",
        "currency": "USD",
        "primaryGenreName": "Hip-Hop/Rap",
        "contentAdvisoryRating": "Explicit",
        "isStreamable": true
    },
    {
        id: 2,
        "wrapperType": "track",
        "kind": "song",
        "artistId": 980795202,
        "collectionId": 1424703172,
        "trackId": 1424704480,
        "artistName": "Marshmello & Bastille",
        "collectionName": "Happier - Single",
        "trackName": "Happier",
        "collectionCensoredName": "Happier - Single",
        "trackCensoredName": "Happier",
        "artistViewUrl": "https://music.apple.com/us/artist/marshmello/980795202?uo=4",
        "collectionViewUrl": "https://music.apple.com/us/album/happier/1424703172?i=1424704480&uo=4",
        "trackViewUrl": "https://music.apple.com/us/album/happier/1424703172?i=1424704480&uo=4",
        "previewUrl": "https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/3d/da/1f/3dda1f28-0b35-76fa-2b05-c658fdcf6af6/mzaf_4564104334568058756.plus.aac.p.m4a",
        "artworkUrl30": "https://is3-ssl.mzstatic.com/image/thumb/Music125/v4/41/fd/79/41fd7950-2fd4-a2f9-0641-1376307dd807/18UMGIM49356.rgb.jpg/30x30bb.jpg",
        "artworkUrl60": "https://is3-ssl.mzstatic.com/image/thumb/Music125/v4/41/fd/79/41fd7950-2fd4-a2f9-0641-1376307dd807/18UMGIM49356.rgb.jpg/60x60bb.jpg",
        "artworkUrl100": "https://is3-ssl.mzstatic.com/image/thumb/Music125/v4/41/fd/79/41fd7950-2fd4-a2f9-0641-1376307dd807/18UMGIM49356.rgb.jpg/100x100bb.jpg",
        "collectionPrice": 1.29,
        "trackPrice": 1.29,
        "releaseDate": "2018-01-11T12:00:00Z",
        "collectionExplicitness": "notExplicit",
        "trackExplicitness": "notExplicit",
        "discCount": 1,
        "discNumber": 1,
        "trackCount": 1,
        "trackNumber": 1,
        "trackTimeMillis": 214290,
        "country": "USA",
        "currency": "USD",
        "primaryGenreName": "Dance",
        "isStreamable": true
    },
    {
        id: 3,
        "wrapperType": "track",
        "kind": "song",
        "artistId": 471260289,
        "collectionId": 1226034336,
        "trackId": 1226034393,
        "artistName": "Harry Styles",
        "collectionName": "Harry Styles",
        "trackName": "Sign of the Times",
        "collectionCensoredName": "Harry Styles",
        "trackCensoredName": "Sign of the Times",
        "artistViewUrl": "https://music.apple.com/us/artist/harry-styles/471260289?uo=4",
        "collectionViewUrl": "https://music.apple.com/us/album/sign-of-the-times/1226034336?i=1226034393&uo=4",
        "trackViewUrl": "https://music.apple.com/us/album/sign-of-the-times/1226034336?i=1226034393&uo=4",
        "previewUrl": "https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/19/f7/99/19f799a3-4638-f354-8713-f5ac076f328e/mzaf_2398941441794619302.plus.aac.p.m4a",
        "artworkUrl30": "https://is4-ssl.mzstatic.com/image/thumb/Music124/v4/3d/5e/aa/3d5eaaa3-9a86-c264-5cd5-7fac83f99a59/886446451978.jpg/30x30bb.jpg",
        "artworkUrl60": "https://is4-ssl.mzstatic.com/image/thumb/Music124/v4/3d/5e/aa/3d5eaaa3-9a86-c264-5cd5-7fac83f99a59/886446451978.jpg/60x60bb.jpg",
        "artworkUrl100": "https://is4-ssl.mzstatic.com/image/thumb/Music124/v4/3d/5e/aa/3d5eaaa3-9a86-c264-5cd5-7fac83f99a59/886446451978.jpg/100x100bb.jpg",
        "collectionPrice": 9.99,
        "trackPrice": 1.29,
        "releaseDate": "2017-04-07T07:00:00Z",
        "collectionExplicitness": "notExplicit",
        "trackExplicitness": "notExplicit",
        "discCount": 1,
        "discNumber": 1,
        "trackCount": 10,
        "trackNumber": 2,
        "trackTimeMillis": 340707,
        "country": "USA",
        "currency": "USD",
        "primaryGenreName": "Pop",
        "isStreamable": false
    },
    {
        id: 4,
        "wrapperType": "track",
        "kind": "song",
        "artistId": 966309175,
        "collectionId": 1373516902,
        "trackId": 1373516920,
        "artistName": "Post Malone",
        "collectionName": "beerbongs & bentleys",
        "trackName": "rockstar (feat. 21 Savage)",
        "collectionCensoredName": "beerbongs & bentleys",
        "trackCensoredName": "rockstar (feat. 21 Savage)",
        "artistViewUrl": "https://music.apple.com/us/artist/post-malone/966309175?uo=4",
        "collectionViewUrl": "https://music.apple.com/us/album/rockstar-feat-21-savage/1373516902?i=1373516920&uo=4",
        "trackViewUrl": "https://music.apple.com/us/album/rockstar-feat-21-savage/1373516902?i=1373516920&uo=4",
        "previewUrl": "https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/10/c1/3d/10c13d42-fd93-9b85-2576-dcdf3d65ef76/mzaf_1879447237147750935.plus.aac.p.m4a",
        "artworkUrl30": "https://is4-ssl.mzstatic.com/image/thumb/Music125/v4/7e/3c/4e/7e3c4ef6-daa7-cc10-57d0-45f5a562eaf5/18UMGIM22101.rgb.jpg/30x30bb.jpg",
        "artworkUrl60": "https://is4-ssl.mzstatic.com/image/thumb/Music125/v4/7e/3c/4e/7e3c4ef6-daa7-cc10-57d0-45f5a562eaf5/18UMGIM22101.rgb.jpg/60x60bb.jpg",
        "artworkUrl100": "https://is4-ssl.mzstatic.com/image/thumb/Music125/v4/7e/3c/4e/7e3c4ef6-daa7-cc10-57d0-45f5a562eaf5/18UMGIM22101.rgb.jpg/100x100bb.jpg",
        "collectionPrice": 9.99,
        "trackPrice": 1.29,
        "releaseDate": "2017-09-15T07:00:00Z",
        "collectionExplicitness": "explicit",
        "trackExplicitness": "explicit",
        "discCount": 1,
        "discNumber": 1,
        "trackCount": 18,
        "trackNumber": 6,
        "trackTimeMillis": 218146,
        "country": "USA",
        "currency": "USD",
        "primaryGenreName": "Hip-Hop/Rap",
        "contentAdvisoryRating": "Explicit",
        "isStreamable": true
    },
    {
        id: 5,
        "wrapperType": "track",
        "kind": "song",
        "artistId": 980795202,
        "collectionId": 1424703172,
        "trackId": 1424704480,
        "artistName": "Marshmello & Bastille",
        "collectionName": "Happier - Single",
        "trackName": "Happier",
        "collectionCensoredName": "Happier - Single",
        "trackCensoredName": "Happier",
        "artistViewUrl": "https://music.apple.com/us/artist/marshmello/980795202?uo=4",
        "collectionViewUrl": "https://music.apple.com/us/album/happier/1424703172?i=1424704480&uo=4",
        "trackViewUrl": "https://music.apple.com/us/album/happier/1424703172?i=1424704480&uo=4",
        "previewUrl": "https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/3d/da/1f/3dda1f28-0b35-76fa-2b05-c658fdcf6af6/mzaf_4564104334568058756.plus.aac.p.m4a",
        "artworkUrl30": "https://is3-ssl.mzstatic.com/image/thumb/Music125/v4/41/fd/79/41fd7950-2fd4-a2f9-0641-1376307dd807/18UMGIM49356.rgb.jpg/30x30bb.jpg",
        "artworkUrl60": "https://is3-ssl.mzstatic.com/image/thumb/Music125/v4/41/fd/79/41fd7950-2fd4-a2f9-0641-1376307dd807/18UMGIM49356.rgb.jpg/60x60bb.jpg",
        "artworkUrl100": "https://is3-ssl.mzstatic.com/image/thumb/Music125/v4/41/fd/79/41fd7950-2fd4-a2f9-0641-1376307dd807/18UMGIM49356.rgb.jpg/100x100bb.jpg",
        "collectionPrice": 1.29,
        "trackPrice": 1.29,
        "releaseDate": "2018-01-11T12:00:00Z",
        "collectionExplicitness": "notExplicit",
        "trackExplicitness": "notExplicit",
        "discCount": 1,
        "discNumber": 1,
        "trackCount": 1,
        "trackNumber": 1,
        "trackTimeMillis": 214290,
        "country": "USA",
        "currency": "USD",
        "primaryGenreName": "Dance",
        "isStreamable": true
    },
    {
        id: 6,
        "wrapperType": "track",
        "kind": "song",
        "artistId": 471260289,
        "collectionId": 1226034336,
        "trackId": 1226034393,
        "artistName": "Harry Styles",
        "collectionName": "Harry Styles",
        "trackName": "Sign of the Times",
        "collectionCensoredName": "Harry Styles",
        "trackCensoredName": "Sign of the Times",
        "artistViewUrl": "https://music.apple.com/us/artist/harry-styles/471260289?uo=4",
        "collectionViewUrl": "https://music.apple.com/us/album/sign-of-the-times/1226034336?i=1226034393&uo=4",
        "trackViewUrl": "https://music.apple.com/us/album/sign-of-the-times/1226034336?i=1226034393&uo=4",
        "previewUrl": "https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/19/f7/99/19f799a3-4638-f354-8713-f5ac076f328e/mzaf_2398941441794619302.plus.aac.p.m4a",
        "artworkUrl30": "https://is4-ssl.mzstatic.com/image/thumb/Music124/v4/3d/5e/aa/3d5eaaa3-9a86-c264-5cd5-7fac83f99a59/886446451978.jpg/30x30bb.jpg",
        "artworkUrl60": "https://is4-ssl.mzstatic.com/image/thumb/Music124/v4/3d/5e/aa/3d5eaaa3-9a86-c264-5cd5-7fac83f99a59/886446451978.jpg/60x60bb.jpg",
        "artworkUrl100": "https://is4-ssl.mzstatic.com/image/thumb/Music124/v4/3d/5e/aa/3d5eaaa3-9a86-c264-5cd5-7fac83f99a59/886446451978.jpg/100x100bb.jpg",
        "collectionPrice": 9.99,
        "trackPrice": 1.29,
        "releaseDate": "2017-04-07T07:00:00Z",
        "collectionExplicitness": "notExplicit",
        "trackExplicitness": "notExplicit",
        "discCount": 1,
        "discNumber": 1,
        "trackCount": 10,
        "trackNumber": 2,
        "trackTimeMillis": 340707,
        "country": "USA",
        "currency": "USD",
        "primaryGenreName": "Pop",
        "isStreamable": false
    },
    {
        id: 7,
        "wrapperType": "track",
        "kind": "song",
        "artistId": 966309175,
        "collectionId": 1373516902,
        "trackId": 1373516920,
        "artistName": "Post Malone",
        "collectionName": "beerbongs & bentleys",
        "trackName": "rockstar (feat. 21 Savage)",
        "collectionCensoredName": "beerbongs & bentleys",
        "trackCensoredName": "rockstar (feat. 21 Savage)",
        "artistViewUrl": "https://music.apple.com/us/artist/post-malone/966309175?uo=4",
        "collectionViewUrl": "https://music.apple.com/us/album/rockstar-feat-21-savage/1373516902?i=1373516920&uo=4",
        "trackViewUrl": "https://music.apple.com/us/album/rockstar-feat-21-savage/1373516902?i=1373516920&uo=4",
        "previewUrl": "https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/10/c1/3d/10c13d42-fd93-9b85-2576-dcdf3d65ef76/mzaf_1879447237147750935.plus.aac.p.m4a",
        "artworkUrl30": "https://is4-ssl.mzstatic.com/image/thumb/Music125/v4/7e/3c/4e/7e3c4ef6-daa7-cc10-57d0-45f5a562eaf5/18UMGIM22101.rgb.jpg/30x30bb.jpg",
        "artworkUrl60": "https://is4-ssl.mzstatic.com/image/thumb/Music125/v4/7e/3c/4e/7e3c4ef6-daa7-cc10-57d0-45f5a562eaf5/18UMGIM22101.rgb.jpg/60x60bb.jpg",
        "artworkUrl100": "https://is4-ssl.mzstatic.com/image/thumb/Music125/v4/7e/3c/4e/7e3c4ef6-daa7-cc10-57d0-45f5a562eaf5/18UMGIM22101.rgb.jpg/100x100bb.jpg",
        "collectionPrice": 9.99,
        "trackPrice": 1.29,
        "releaseDate": "2017-09-15T07:00:00Z",
        "collectionExplicitness": "explicit",
        "trackExplicitness": "explicit",
        "discCount": 1,
        "discNumber": 1,
        "trackCount": 18,
        "trackNumber": 6,
        "trackTimeMillis": 218146,
        "country": "USA",
        "currency": "USD",
        "primaryGenreName": "Hip-Hop/Rap",
        "contentAdvisoryRating": "Explicit",
        "isStreamable": true
    },
    {
        id: 8,
        "wrapperType": "track",
        "kind": "song",
        "artistId": 980795202,
        "collectionId": 1424703172,
        "trackId": 1424704480,
        "artistName": "Marshmello & Bastille",
        "collectionName": "Happier - Single",
        "trackName": "Happier",
        "collectionCensoredName": "Happier - Single",
        "trackCensoredName": "Happier",
        "artistViewUrl": "https://music.apple.com/us/artist/marshmello/980795202?uo=4",
        "collectionViewUrl": "https://music.apple.com/us/album/happier/1424703172?i=1424704480&uo=4",
        "trackViewUrl": "https://music.apple.com/us/album/happier/1424703172?i=1424704480&uo=4",
        "previewUrl": "https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/3d/da/1f/3dda1f28-0b35-76fa-2b05-c658fdcf6af6/mzaf_4564104334568058756.plus.aac.p.m4a",
        "artworkUrl30": "https://is3-ssl.mzstatic.com/image/thumb/Music125/v4/41/fd/79/41fd7950-2fd4-a2f9-0641-1376307dd807/18UMGIM49356.rgb.jpg/30x30bb.jpg",
        "artworkUrl60": "https://is3-ssl.mzstatic.com/image/thumb/Music125/v4/41/fd/79/41fd7950-2fd4-a2f9-0641-1376307dd807/18UMGIM49356.rgb.jpg/60x60bb.jpg",
        "artworkUrl100": "https://is3-ssl.mzstatic.com/image/thumb/Music125/v4/41/fd/79/41fd7950-2fd4-a2f9-0641-1376307dd807/18UMGIM49356.rgb.jpg/100x100bb.jpg",
        "collectionPrice": 1.29,
        "trackPrice": 1.29,
        "releaseDate": "2018-01-11T12:00:00Z",
        "collectionExplicitness": "notExplicit",
        "trackExplicitness": "notExplicit",
        "discCount": 1,
        "discNumber": 1,
        "trackCount": 1,
        "trackNumber": 1,
        "trackTimeMillis": 214290,
        "country": "USA",
        "currency": "USD",
        "primaryGenreName": "Dance",
        "isStreamable": true
    },
    {
        id: 9,
        "wrapperType": "track",
        "kind": "song",
        "artistId": 471260289,
        "collectionId": 1226034336,
        "trackId": 1226034393,
        "artistName": "Harry Styles",
        "collectionName": "Harry Styles",
        "trackName": "Sign of the Times",
        "collectionCensoredName": "Harry Styles",
        "trackCensoredName": "Sign of the Times",
        "artistViewUrl": "https://music.apple.com/us/artist/harry-styles/471260289?uo=4",
        "collectionViewUrl": "https://music.apple.com/us/album/sign-of-the-times/1226034336?i=1226034393&uo=4",
        "trackViewUrl": "https://music.apple.com/us/album/sign-of-the-times/1226034336?i=1226034393&uo=4",
        "previewUrl": "https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/19/f7/99/19f799a3-4638-f354-8713-f5ac076f328e/mzaf_2398941441794619302.plus.aac.p.m4a",
        "artworkUrl30": "https://is4-ssl.mzstatic.com/image/thumb/Music124/v4/3d/5e/aa/3d5eaaa3-9a86-c264-5cd5-7fac83f99a59/886446451978.jpg/30x30bb.jpg",
        "artworkUrl60": "https://is4-ssl.mzstatic.com/image/thumb/Music124/v4/3d/5e/aa/3d5eaaa3-9a86-c264-5cd5-7fac83f99a59/886446451978.jpg/60x60bb.jpg",
        "artworkUrl100": "https://is4-ssl.mzstatic.com/image/thumb/Music124/v4/3d/5e/aa/3d5eaaa3-9a86-c264-5cd5-7fac83f99a59/886446451978.jpg/100x100bb.jpg",
        "collectionPrice": 9.99,
        "trackPrice": 1.29,
        "releaseDate": "2017-04-07T07:00:00Z",
        "collectionExplicitness": "notExplicit",
        "trackExplicitness": "notExplicit",
        "discCount": 1,
        "discNumber": 1,
        "trackCount": 10,
        "trackNumber": 2,
        "trackTimeMillis": 340707,
        "country": "USA",
        "currency": "USD",
        "primaryGenreName": "Pop",
        "isStreamable": false
    },
    {
        id: 10,
        "wrapperType": "track",
        "kind": "song",
        "artistId": 966309175,
        "collectionId": 1373516902,
        "trackId": 1373516920,
        "artistName": "Post Malone",
        "collectionName": "beerbongs & bentleys",
        "trackName": "rockstar (feat. 21 Savage)",
        "collectionCensoredName": "beerbongs & bentleys",
        "trackCensoredName": "rockstar (feat. 21 Savage)",
        "artistViewUrl": "https://music.apple.com/us/artist/post-malone/966309175?uo=4",
        "collectionViewUrl": "https://music.apple.com/us/album/rockstar-feat-21-savage/1373516902?i=1373516920&uo=4",
        "trackViewUrl": "https://music.apple.com/us/album/rockstar-feat-21-savage/1373516902?i=1373516920&uo=4",
        "previewUrl": "https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/10/c1/3d/10c13d42-fd93-9b85-2576-dcdf3d65ef76/mzaf_1879447237147750935.plus.aac.p.m4a",
        "artworkUrl30": "https://is4-ssl.mzstatic.com/image/thumb/Music125/v4/7e/3c/4e/7e3c4ef6-daa7-cc10-57d0-45f5a562eaf5/18UMGIM22101.rgb.jpg/30x30bb.jpg",
        "artworkUrl60": "https://is4-ssl.mzstatic.com/image/thumb/Music125/v4/7e/3c/4e/7e3c4ef6-daa7-cc10-57d0-45f5a562eaf5/18UMGIM22101.rgb.jpg/60x60bb.jpg",
        "artworkUrl100": "https://is4-ssl.mzstatic.com/image/thumb/Music125/v4/7e/3c/4e/7e3c4ef6-daa7-cc10-57d0-45f5a562eaf5/18UMGIM22101.rgb.jpg/100x100bb.jpg",
        "collectionPrice": 9.99,
        "trackPrice": 1.29,
        "releaseDate": "2017-09-15T07:00:00Z",
        "collectionExplicitness": "explicit",
        "trackExplicitness": "explicit",
        "discCount": 1,
        "discNumber": 1,
        "trackCount": 18,
        "trackNumber": 6,
        "trackTimeMillis": 218146,
        "country": "USA",
        "currency": "USD",
        "primaryGenreName": "Hip-Hop/Rap",
        "contentAdvisoryRating": "Explicit",
        "isStreamable": true
    },
    {
        id: 11,
        "wrapperType": "track",
        "kind": "song",
        "artistId": 980795202,
        "collectionId": 1424703172,
        "trackId": 1424704480,
        "artistName": "Marshmello & Bastille",
        "collectionName": "Happier - Single",
        "trackName": "Happier",
        "collectionCensoredName": "Happier - Single",
        "trackCensoredName": "Happier",
        "artistViewUrl": "https://music.apple.com/us/artist/marshmello/980795202?uo=4",
        "collectionViewUrl": "https://music.apple.com/us/album/happier/1424703172?i=1424704480&uo=4",
        "trackViewUrl": "https://music.apple.com/us/album/happier/1424703172?i=1424704480&uo=4",
        "previewUrl": "https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/3d/da/1f/3dda1f28-0b35-76fa-2b05-c658fdcf6af6/mzaf_4564104334568058756.plus.aac.p.m4a",
        "artworkUrl30": "https://is3-ssl.mzstatic.com/image/thumb/Music125/v4/41/fd/79/41fd7950-2fd4-a2f9-0641-1376307dd807/18UMGIM49356.rgb.jpg/30x30bb.jpg",
        "artworkUrl60": "https://is3-ssl.mzstatic.com/image/thumb/Music125/v4/41/fd/79/41fd7950-2fd4-a2f9-0641-1376307dd807/18UMGIM49356.rgb.jpg/60x60bb.jpg",
        "artworkUrl100": "https://is3-ssl.mzstatic.com/image/thumb/Music125/v4/41/fd/79/41fd7950-2fd4-a2f9-0641-1376307dd807/18UMGIM49356.rgb.jpg/100x100bb.jpg",
        "collectionPrice": 1.29,
        "trackPrice": 1.29,
        "releaseDate": "2018-01-11T12:00:00Z",
        "collectionExplicitness": "notExplicit",
        "trackExplicitness": "notExplicit",
        "discCount": 1,
        "discNumber": 1,
        "trackCount": 1,
        "trackNumber": 1,
        "trackTimeMillis": 214290,
        "country": "USA",
        "currency": "USD",
        "primaryGenreName": "Dance",
        "isStreamable": true
    }
]

export interface Song {
    album: string;
    artist_view_url: string;
    artists: string;
    artwork_url: string;
    country: string;
    currency: string;
    explicit: string;
    genre: string;
    preview_url: string;
    price: string;
    release_date: Date;
    song_id: number;
    song_name: string;
    track_count: number;
    track_length: number;
    track_number: number;
    track_view_url: string;
}

export interface SongResponse {
    artists: any[][];
    concerts: any[][];
    songs: Song[];
}

export interface SongsResponse {
    length: number;
    songs: Song[];
    total_rows: number;
}

export const song_page_service = {
    getData: ({ from, to, requestLink }) => {
        let n = to - from
        requestLink += `&n=${n}&offset=${from}`
        return new Promise((resolve) => {
            fetch(requestLink)
                .then(function (response) {
                    return response.text();
                })
                .then(function (myJson) {
                    let songsResponse = songsJsonToObject(myJson)
                    let songs = songsResponse.songs
                    resolve({
                        count: songsResponse.total_rows,
                        data: songs
                    })
                });
        });
    }
}

export function songsJsonToObject(json: string): SongsResponse {
    let result: SongsResponse = JSON.parse(json)
    return result;
}

export function songJsonToObject(json: string): SongResponse {
    let result: SongResponse = JSON.parse(json)
    return result;
}