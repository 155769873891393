import React from "react";
import { Scatter } from "react-chartjs-2";
import Chart from "chart.js/auto"

export const ScatterChartJS = ({ dataSetLabel, yData }) => {
    const data = {
        datasets: [
            {
                label: dataSetLabel,
                backgroundColor: "rgb(255, 99, 132)",
                data: yData,
            },
        ],
    };
    return (
        <div className="chart">
            <Scatter data={data} />
        </div >
    );
};

export default ScatterChartJS;