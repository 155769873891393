export interface Concert {
    artist_name: string;
    concert_id: number;
    event_description: string;
    event_name: string;
    image_url: string;
    predicted_attendance: number;
    start_date: string;
    start_time: string;
    time_zone: string;
    venue_address: string;
    venue_name: string;
}
export interface ConcertResponse {
    artists: any[][];
    concerts: Concert[];
    songs: any[][];
}
export interface ConcertsResponse {
    concerts: Concert[];
    length: number;
    total_rows: number;
}

export const concert_page_service = {
    getData: ({ from, to, requestLink }) => {
        let n = to - from
        requestLink += `&n=${n}&offset=${from}`
        return new Promise((resolve) => {
            fetch(requestLink)
                .then(function (response) {
                    return response.text();
                })
                .then(function (myJson) {
                    let concertsResponse = concertsJsonToObject(myJson)
                    let concerts = concertsResponse.concerts
                    resolve({
                        count: concertsResponse.total_rows,
                        data: concerts
                    })
                });
        });
    }
}

export function concertsJsonToObject(json: string): ConcertsResponse {
    let result: ConcertsResponse = JSON.parse(json)
    return result;
}

export function concertJsonToObject(json: string): ConcertResponse {
    let result: ConcertResponse = JSON.parse(json)
    return result;
}




