import React from 'react'
import { Helmet } from 'react-helmet';
import SearchCard from '../components/SearchCard';


const Search = () => {
  return (
    <div>Songs
      <Helmet>
        <title>Search</title>
      </Helmet>
      <div><SearchCard /></div>
    </div>
  )
}

export default Search