import React, { useState, useEffect } from 'react'
import { tracksJsonToObject } from '../data/providerSongData';
import { artistsJsonToObject } from '../data/providerArtistData';

import { BarChartJS } from '../components/BarChart'
import { ScatterChartJS } from '../components/ScatterChartJS'



const ProviderVisualizations = () => {
    const [releaseYearToNumSongs, setReleaseYearToNumSongs] = useState(new Map());
    const [numFollowersToPopularity, setnumFollowersToPopularity] = useState([{}]);
    const [genreToAveragePopularity, setGenreToAveragePopularity] = useState(new Map());

    useEffect(() => {
        fetch('https://songsource.me/api/search?model=track&pagesize=20000', {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        })
            .then(function (response) {
                return response.text();
            })
            .then(function (myJson) {
                let map = new Map();
                let tracksResponse = tracksJsonToObject(myJson)
                tracksResponse.forEach(function (track) {
                    let date = new Date(track.release_date)
                    let year = date.getFullYear()

                    map.set(year, map.get(year) == undefined ? 1 : (map.get(year)) + 1)

                });
                const mapSort1 = new Map([...map.entries()].sort((a, b) => b[0] - a[0]));
                setReleaseYearToNumSongs(mapSort1)

                // change running totals and counts of genre to popularity

            });
        fetch('https://songsource.me/api/search?model=artist&pagesize=20000', {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        })
            .then(function (response) {
                return response.text();
            })
            .then(function (myJson) {

                let artistResponse = artistsJsonToObject(myJson)
                let data = []
                let map = new Map();
                artistResponse.forEach(function (artist) {
                    let genre = artist.genre == undefined ? "unknown" : artist.genre
                    let popularity = artist.popularity

                    map.set(genre, map.get(genre) == undefined ? [0, 0] : (map.get(genre)))
                    let newVal = map.get(genre)
                    newVal[0] = newVal[0] + 1
                    newVal[1] = newVal[1] + popularity
                    map.set(genre, newVal)
                    data.push({ x: artist.popularity, y: artist.num_followers })
                });
                setnumFollowersToPopularity(data)

                let map2 = new Map()
                for (let [key, value] of map) {
                    map2.set(key, value[1] / value[0])
                }
                const map2Sort = new Map([...map2.entries()].sort((a, b) => b[1] - a[1]));
                setGenreToAveragePopularity(map2Sort)
            });
    }, [])

    return (
        <div style={{ marginTop: 100, textAlign: "center" }}>
            <h1>Provider Visualizations</h1>

            <h2>Song Release Year to Song Count</h2>
            <BarChartJS xLabels={Array.from(releaseYearToNumSongs.keys())} dataSetLabel={"Song Count"} yData={Array.from(releaseYearToNumSongs.values())}></BarChartJS>
            <h2>Trend Between Artist Popularity and Artist Followers</h2>
            <ScatterChartJS dataSetLabel={"Popularity to Followers"} yData={numFollowersToPopularity}></ScatterChartJS>
            <h2>Artist Genre To Average Popularity</h2>
            <BarChartJS xLabels={Array.from(genreToAveragePopularity.keys())} dataSetLabel={"Average Popularity"} yData={Array.from(genreToAveragePopularity.values())}></BarChartJS>

        </div>

    )
};

export default ProviderVisualizations;