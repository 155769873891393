import React from 'react';
import { Helmet } from 'react-helmet';
import 'bootstrap/dist/css/bootstrap.css'
import Navb from './components/Nav';
import './App.css';
import { Outlet } from "react-router-dom";
import SplashCards from './components/SplashCards';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Artists from "./routes/Artists";
import About from "./routes/About";
import Concerts from "./routes/Concerts";
import Songs from "./routes/Songs";
import Home from "./routes/Home"
import ArtistDetail from './components/ArtistDetail';
import ConcertCard from './components/ConcertCard';
import ConcertDetail from './components/ConcertDetail';
import SongDetail from './components/SongDetail';
import Search from './routes/Search';
import Visualizations from './routes/Visualizations';
import ProviderVisualizations from './routes/ProviderVisualizations';

function App() {
  return (
    <>
      <div className="App" style={{backgroundColor: '#F7EBF5'}}>
        {/* <Helmet style = {{background-color: '#F7EBF5'}}/> */}
        <Navb></Navb>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/concerts" element={<Concerts />} />
          <Route path="/concerts/:id" element={<ConcertDetail />} />
          <Route path="/artists" element={<Artists />} />
          <Route path="/artistdetail/:artist_id" element={<ArtistDetail />} />
          <Route path="/songs" element={<Songs />} />
          <Route path="/songs/:id" element={<SongDetail />} />
          <Route path="/search" element={<Search />} />
          <Route path="/visualizations" element={<Visualizations />} />
          <Route path="/providervisualizations" element={<ProviderVisualizations />} />
        </Routes>
      </div>
    </>
  );
}

document.body.style.backgroundColor = "#F7EBF5";
export default App;

